import { Route } from '@angular/router';
import {
  AuthPipe,
  canActivate,
  hasCustomClaim,
  loggedIn,
} from '@angular/fire/auth-guard';
import { forkJoin, map, mergeMap, of, pipe } from 'rxjs';

const isLoggedInAsAdmin: AuthPipe = pipe(
  mergeMap((user) =>
    forkJoin([loggedIn(of(user)), hasCustomClaim('admin')(of(user))]),
  ),
  map(([isLoggedIn, isAdmin]) => {
    if (!isLoggedIn) return '/auth/login';
    if (!isAdmin) return '/auth/unauthorized';
    return true;
  }),
);

export const appRoutes: Route[] = [
  {
    path: '',
    ...canActivate(() => isLoggedInAsAdmin),
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@goalmate/admin/home').then((m) => m.AdminHomeModule),
      },
    ],
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('@goalmate/admin/auth').then((m) => m.AdminAuthModule),
  },
  { path: '**', redirectTo: '/' },
];
