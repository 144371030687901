export enum TimerSeconds {
  SHOW_SECONDS = 'Show seconds',
  HIDE_SECONDS = 'Hide seconds',
}

export const timerSeconds = [
  TimerSeconds.SHOW_SECONDS,
  TimerSeconds.HIDE_SECONDS,
];

export const maxDailyUsage = 3;

export const USER_PUBLIC_FIELDS = ['displayName', 'photoURL'];

export const USER_LIVES = 3;
