import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Goal, GoalsFilter } from '@goalmate/typings';

export const BankGoalActions = createActionGroup({
  source: 'BankGoal/API',
  events: {
    'Load BankGoals': props<{ filter: GoalsFilter }>(),
    'Load BankGoals Error': props<{ error: string }>(),
    'Set All BankGoals': props<{ bankGoals: Goal[] }>(),
    'Add BankGoal': props<{ bankGoal: Goal }>(),
    'Upsert BankGoal': props<{ bankGoal: Goal }>(),
    'Add BankGoals': props<{ bankGoals: Goal[] }>(),
    'Upsert BankGoals': props<{ bankGoals: Goal[] }>(),
    'Update BankGoal': props<{ bankGoal: Update<Goal> }>(),
    'Update BankGoals': props<{ bankGoals: Update<Goal>[] }>(),
    'Delete BankGoal': props<{ id: string }>(),
    'Delete BankGoals': props<{ ids: string[] }>(),
    'Clear BankGoals': emptyProps(),
    'Set BankGoals Filter': props<{ filter: GoalsFilter }>(),
    'Set Has Next Page': props<{ hasNextPage: boolean }>(),
    'Load Next Page': emptyProps(),
    'Update Is Loading next page': props<{ isLoadingNextPage: boolean }>(),
  },
});
