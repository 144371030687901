import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PostsActions } from './posts.actions';
import { catchError, from, of, switchMap, withLatestFrom } from 'rxjs';
import { PostsRepositoryService } from '@goalmate/repository';
import { POST_COMMENTS_PER_PAGE, POSTS_PER_PAGE } from '@goalmate/typings';
import { Store } from '@ngrx/store';
import {
  selectHasNextPage,
  selectLastPost,
  selectPostCommentsById,
} from './posts.selectors';

@Injectable()
export class PostsEffects {
  private actions$ = inject(Actions);
  private postsDB = inject(PostsRepositoryService);
  private store = inject(Store);

  loadPosts$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PostsActions.loadPosts),
      switchMap(() => {
        return from(this.postsDB.getCommunityPosts()).pipe(
          switchMap((posts) => {
            return [
              PostsActions.setPosts({ posts }),
              PostsActions.setHasNextPage({
                hasNextPage: posts.length === POSTS_PER_PAGE,
              }),
            ];
          }),
          catchError((error) => {
            console.error(error);
            return of(
              PostsActions.loadPostsError({
                error: error.code || 'Error loading posts',
              }),
            );
          }),
        );
      }),
    );
  });

  loadNextPage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PostsActions.loadNextPage),
      withLatestFrom(
        this.store.select(selectLastPost),
        this.store.select(selectHasNextPage),
      ),
      switchMap(([, post, hasNextPage]) => {
        if (!hasNextPage)
          return of(
            PostsActions.updateIsLoadingNextPage({
              isLoadingNextPage: false,
            }),
          );
        const postId = post?.id as string;
        return from(this.postsDB.getCommunityPosts(postId)).pipe(
          switchMap((posts) => {
            return [
              PostsActions.addPosts({ posts }),
              PostsActions.setHasNextPage({
                hasNextPage: posts.length === POSTS_PER_PAGE,
              }),
              PostsActions.updateIsLoadingNextPage({
                isLoadingNextPage: false,
              }),
            ];
          }),
          catchError((error) => {
            console.error(error);
            return of(
              PostsActions.loadPostsError({
                error: error.code || 'Error loading posts',
              }),
            );
          }),
        );
      }),
    );
  });

  loadPostComments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PostsActions.loadComments),
      switchMap(({ postId }) =>
        from(this.postsDB.getCommentsByPostId(postId)).pipe(
          switchMap((comments) =>
            of(
              PostsActions.updatePost({
                post: {
                  id: postId,
                  changes: {
                    comments,
                    commentsLoaded: true,
                    isLoadingComments: false,
                    commentsError: undefined,
                    hasNextComment: comments.length === POST_COMMENTS_PER_PAGE,
                  },
                },
              }),
            ),
          ),
          catchError((error) => {
            console.error(error);
            return of(
              PostsActions.updatePost({
                post: {
                  id: postId,
                  changes: {
                    comments: undefined,
                    commentsLoaded: false,
                    isLoadingComments: false,
                    commentsError: error.code || 'Server error',
                  },
                },
              }),
            );
          }),
        ),
      ),
    ),
  );
}
