import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  GOALS_BANK_FEATURE_KEY,
  GoalsBankStatsState,
} from './goals-bank.reducer';
import {
  GoalBankState,
  goalBankFeatureKey,
} from '../reducers/goal-bank.reducers';

export const selectGoalsBankFeatureState =
  createFeatureSelector<GoalBankState>(goalBankFeatureKey);

export const selectGoalsBankState = createSelector(
  selectGoalsBankFeatureState,
  (state) => state[GOALS_BANK_FEATURE_KEY],
);

export const selectGoalsBankLoaded = createSelector(
  selectGoalsBankState,
  (state: GoalsBankStatsState) => state.loaded,
);

export const selectGoalsBankError = createSelector(
  selectGoalsBankState,
  (state: GoalsBankStatsState) => state.error,
);

export const selectTotalStats = createSelector(
  selectGoalsBankState,
  (state: GoalsBankStatsState) => state.stats,
);

export const selectRoutesTracker = createSelector(
  selectGoalsBankState,
  (state: GoalsBankStatsState) => state.routesTracker,
);

export const selectPreviousUrl = createSelector(
  selectRoutesTracker,
  (tracker) => {
    return tracker ? tracker.previousUrl : null;
  },
);

export const selectCompletedGoals = createSelector(
  selectGoalsBankState,
  (state: GoalsBankStatsState) => state.completedGoals,
);
