import { Injectable, inject } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { switchMap, catchError, of, from } from 'rxjs';
import * as GoalsBankActions from './goals-bank.actions';
import * as GoalsBankFeature from './goals-bank.reducer';
import { GoalsRepositoryService } from '@goalmate/repository';

@Injectable()
export class GoalsBankEffects {
  private actions$ = inject(Actions);
  private goalsDb = inject(GoalsRepositoryService);

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GoalsBankActions.initGoalsBank),
      switchMap(({ userId }) => {
        return from(this.goalsDb.getGoalsStats(userId)).pipe(
          switchMap((stats) =>
            of(GoalsBankActions.loadGoalsBankSuccess({ stats })),
          ),
          catchError((error) => {
            console.error('Error', error);
            return of(GoalsBankActions.loadGoalsBankFailure({ error }));
          }),
        );
      }),
    ),
  );
}
