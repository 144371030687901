import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Goal } from '@goalmate/typings';
import { EntityMap, EntityMapOne, Update } from '@ngrx/entity';

export const GoalsActions = createActionGroup({
  source: 'Goals',
  events: {
    Init: emptyProps(),
    'Get All By User Id': props<{ userId: string }>(),
    'Get Active Goals': props<{ userId: string }>(),
    Error: props<{ error: string }>(),
    'Set Goal': props<{ goal: Goal }>(),
    'Set Goals': props<{ goals: Goal[] }>(),
    'Add Goal': props<{ goal: Goal }>(),
    'Add Goals': props<{ goals: Goal[] }>(),
    'Upsert Goal': props<{ goal: Goal }>(),
    'Upsert Goals': props<{ goals: Goal[] }>(),
    'Update Goal': props<{ update: Update<Goal> }>(),
    'Update Goals': props<{ updates: Update<Goal>[] }>(),
    'Map Goal': props<{ entityMap: EntityMapOne<Goal> }>(),
    'Map Goals': props<{ entityMap: EntityMap<Goal> }>(),
    'Delete Goal': props<{ id: string }>(),
    'Delete Goals': props<{ ids: string[] }>(),
    'Delete Goals By Predicate': props<{
      predicate: (goal: Goal) => boolean;
    }>(),
    'Clear Goals': emptyProps(),
    'Select Goal': props<{ id: string | null }>(),
    'Select First Goal': emptyProps(),
    'Load Messages': props<{ goalId: string; lastMessageId?: string }>(),
    'Load Next Page': props<{
      userId: string;
      lastGoalId?: string;
      limit?: number;
    }>(),
    'Update Goals Pagination': props<{ hasNextPage: boolean }>(),
    'Update Report Checked': props<{
      reportChecked: boolean;
    }>(),
  },
});
