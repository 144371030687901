import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Dictionary } from '@ngrx/entity';
import { ContentMessage } from '@goalmate/typings';
import { HomeState, homeFeatureKey } from '../reducers/home.reducers';
import { ContentMessagesState, adapter } from './content-message.reducer';

const { selectIds, selectTotal, selectAll, selectEntities } =
  adapter.getSelectors();

const selectHomeFeatureState = createFeatureSelector<HomeState>(homeFeatureKey);

export const selectContentMsgState = createSelector(
  selectHomeFeatureState,
  (state) => state.contentMessages,
);

/**
 * select the array of ids
 */
export const selectMessagesIds = createSelector(
  selectContentMsgState,
  (state: ContentMessagesState) => selectIds(state),
);

/**
 * select the array of Messages
 */
export const selectAllMessages = createSelector(
  selectContentMsgState,
  (state: ContentMessagesState) => selectAll(state),
);

/**
 * select the total count
 */
export const selectMessagesTotal = createSelector(
  selectContentMsgState,
  (state: ContentMessagesState) => selectTotal(state),
);

export const selectIsLoaded = createSelector(
  selectContentMsgState,
  (state: ContentMessagesState) => state.isLoaded,
);

export const selectMessageById = (messageId: string) =>
  createSelector(
    selectContentMsgState,
    (state: ContentMessagesState) => state.entities[messageId],
  );

export const selectIsLoading = createSelector(
  selectContentMsgState,
  (state: ContentMessagesState) => state.isLoading,
);

export const selectError = createSelector(
  selectContentMsgState,
  (state: ContentMessagesState) => state.error,
);

export const selectHasNextPage = createSelector(
  selectContentMsgState,
  (state: ContentMessagesState) => state.hasNextPage,
);

export const selectMessageEntities = createSelector(
  selectContentMsgState,
  (state: ContentMessagesState) => selectEntities(state),
);

export const selectLastMessage = createSelector(
  selectContentMsgState,
  selectMessageEntities,
  selectMessagesIds,
  selectMessagesTotal,
  (
    state: ContentMessagesState,
    messages: Dictionary<ContentMessage>,
    ids: string[] | number[],
    total: number,
  ) => {
    if (!state || total === 0) return null;
    const lastMstId = ids[total - 1];
    const lastMsg = messages[lastMstId];
    return lastMsg;
  },
);

export const selectFirstMessage = createSelector(
  selectContentMsgState,
  selectMessageEntities,
  selectMessagesIds,
  (
    state: ContentMessagesState,
    messages: Dictionary<ContentMessage>,
    ids: string[] | number[],
  ) => {
    if (!state || !ids.length) return null;
    const firstMstId = ids[0];
    const firstMsg = messages[firstMstId];
    return firstMsg;
  },
);

export const selectUnreadCount = createSelector(
  selectContentMsgState,
  (state: ContentMessagesState) => state.unreadCount,
);
