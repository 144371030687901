import { ActionReducer, MetaReducer } from '@ngrx/store';

/**
 * It clears state on logout
 * @param reducer
 * @returns
 */
function logout(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state, action) {
    if (action.type === '[Auth/Login] Logout') {
      state = {};
    }
    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<any>[] = [logout];
