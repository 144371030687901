import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { PostsActions } from './posts.actions';
import { PostCommunity } from '@goalmate/typings';

export const postsesFeatureKey = 'posts';

export interface PostsState extends EntityState<PostCommunity> {
  selectedPostId: string | undefined;
  loaded: boolean;
  isLoading: boolean;
  error?: string | null;
  hasNextPage?: boolean;
  isLoadingNextPage?: boolean;
}

export const adapter: EntityAdapter<PostCommunity> =
  createEntityAdapter<PostCommunity>({
    selectId: (post: PostCommunity) => post.id,
  });

export const initialState: PostsState = adapter.getInitialState({
  selectedPostId: undefined,
  loaded: false,
  isLoading: false,
});

export const reducer = createReducer(
  initialState,
  on(PostsActions.loadPosts, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(PostsActions.loadPostsError, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error,
  })),
  on(PostsActions.addPost, (state, action) =>
    adapter.addOne(action.post, state),
  ),
  on(PostsActions.upsertPost, (state, action) =>
    adapter.upsertOne(action.post, state),
  ),
  on(PostsActions.addPosts, (state, action) =>
    adapter.addMany(action.posts, state),
  ),
  on(PostsActions.upsertPosts, (state, action) =>
    adapter.upsertMany(action.posts, state),
  ),
  on(PostsActions.updatePost, (state, action) =>
    adapter.updateOne(action.post, state),
  ),
  on(PostsActions.updatePosts, (state, action) =>
    adapter.updateMany(action.posts, state),
  ),
  on(PostsActions.deletePost, (state, action) =>
    adapter.removeOne(action.id, state),
  ),
  on(PostsActions.deletePosts, (state, action) =>
    adapter.removeMany(action.ids, state),
  ),
  on(PostsActions.setPosts, (state, action) =>
    adapter.setAll(action.posts, { ...state, loaded: true, isLoading: false }),
  ),
  on(PostsActions.clearPosts, (state) => adapter.removeAll(state)),
  on(PostsActions.setHasNextPage, (state, action) => ({
    ...state,
    hasNextPage: action.hasNextPage,
  })),
  on(PostsActions.updateIsLoadingNextPage, (state, action) => ({
    ...state,
    isLoadingNextPage: action.isLoadingNextPage,
  })),
  on(PostsActions.loadNextPage, (state) => ({
    ...state,
    isLoadingNextPage: true,
  })),
  on(PostsActions.selectPost, (state, action) => ({
    ...state,
    selectedPostId: action.id,
  })),
  on(PostsActions.loadComments, (state, { postId }) =>
    adapter.updateOne(
      { id: postId, changes: { isLoadingComments: true } },
      state,
    ),
  ),
);

export function postsReducer(state: PostsState | undefined, action: Action) {
  return reducer(state, action);
}
