import {
  DocumentData,
  Firestore,
  QueryDocumentSnapshot,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
  DocumentSnapshot,
} from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { Price } from '@goalmate/typings';

@Injectable({
  providedIn: 'root',
})
export class PricesRepositoryService {
  protected collectionStr = 'prices';

  constructor(protected firestore: Firestore) {}

  /**
   * This function returns a promise that resolves to a Price object or
   * null if the price does not exist.
   * @param {string} id - goal id
   * @returns {Promise<Price | null>} - price object or null
   */
  async getById(id: string): Promise<Price | null> {
    const goalRef = doc(this.firestore, `${this.collectionStr}/${id}`);
    const goalSnap = await getDoc(goalRef);
    if (goalSnap.exists()) {
      return this.convertPriceSnapshot(goalSnap);
    }
    return null;
  }

  /**
   * This function prices
   * @returns {Promise<Price[]>} - price array
   */
  async getAll(): Promise<Price[]> {
    const collectionRef = collection(this.firestore, this.collectionStr);
    const q = query(
      collectionRef,
      where('active', '==', true),
      orderBy('unit_amount', 'asc'),
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(this.convertPriceSnapshot);
  }

  private convertPriceSnapshot(
    goalSnap:
      | QueryDocumentSnapshot<DocumentData>
      | DocumentSnapshot<DocumentData>,
  ): Price {
    return goalSnap.data() as Price;
  }
}
