import { InjectionToken } from '@angular/core';
import {
  GOALS_BANK_FEATURE_KEY,
  GoalsBankStatsState,
  goalsBankStatsReducer,
} from '../goal-bank-stats/goals-bank.reducer';
import { ActionReducerMap } from '@ngrx/store';
import {
  GoalsBankGoalsState,
  bankGoalsFeatureKey,
  goalBankGoalsReducer,
} from '../goal-bank-goals/bank-goal.reducer';

export const goalBankFeatureKey = 'stats';

export interface GoalBankState {
  [GOALS_BANK_FEATURE_KEY]: GoalsBankStatsState;
  [bankGoalsFeatureKey]: GoalsBankGoalsState;
}

export const goalBankReducers = new InjectionToken<
  ActionReducerMap<GoalBankState>
>(goalBankFeatureKey, {
  factory: () => ({
    [GOALS_BANK_FEATURE_KEY]: goalsBankStatsReducer,
    [bankGoalsFeatureKey]: goalBankGoalsReducer,
  }),
});
