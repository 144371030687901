import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ContentActions } from './content.actions';
import { catchError, from, of, switchMap } from 'rxjs';
import { ContentRepositoryService } from '@goalmate/repository';

@Injectable()
export class ContentEffects {
  private actions$ = inject(Actions);
  private contentDB = inject(ContentRepositoryService);

  loadContent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ContentActions.loadContents),
      switchMap(() => {
        return from(this.contentDB.getContents()).pipe(
          switchMap((contents) =>
            of(ContentActions.loadContentsSuccess({ contents })),
          ),
          catchError((error) => {
            console.error('Error', error);
            return of(
              ContentActions.loadContentsError({
                error: error.code || 'Server error',
              }),
            );
          }),
        );
      }),
    );
  });
}
