import {
  DocumentData,
  DocumentSnapshot,
  Firestore,
  QueryDocumentSnapshot,
  Timestamp,
  addDoc,
  collection,
  collectionData,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { Program, ProgramDto, ProgramStatus } from '@goalmate/typings';
import { limit } from 'firebase/firestore';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProgramRepositoryService {
  protected collectionStr = 'programs';

  constructor(protected firestore: Firestore) {}

  /**
   * This function takes a user Id and returns a promise that resolves to a Program array
   * @param {string} userId string
   * @returns {Promise<Program[]>} array of programs
   */
  async getPrograms(userId: string): Promise<Program[]> {
    const collectionRef = collection(this.firestore, this.collectionStr);
    const q = query(collectionRef, where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => this.convertSnapshotToPrograms(doc));
  }

  /**
   * This function takes a user Id and returns a promise that resolves to a Program
   * @param {string} userId string
   * @returns {Promise<Program>} Program
   */
  async getActiveProgram(userId: string): Promise<Program | null> {
    const collectionRef = collection(this.firestore, this.collectionStr);
    const q = query(
      collectionRef,
      where('userId', '==', userId),
      where('status', '==', ProgramStatus.ACTIVE),
      limit(1),
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) =>
      this.convertSnapshotToPrograms(doc),
    )[0];
  }

  /**
   * This function takes a user Id and returns an observable of active Program
   * @param {string} userId string
   * @returns {Observable<Program>} Program
   */
  activeProgram$(userId: string): Observable<Program | null> {
    const collectionRef = collection(this.firestore, this.collectionStr);
    const q = query(
      collectionRef,
      where('userId', '==', userId),
      where('status', '==', ProgramStatus.ACTIVE),
      limit(1),
    );
    return collectionData(q, { idField: 'id' }).pipe(
      map((documentsData) => {
        const program = documentsData[0];
        if (!program) return null;
        return {
          ...program,
          createdAt: program?.['createdAt']?.toDate(),
          updatedAt: program?.['updatedAt']?.toDate(),
          nextCheck: program?.['nextCheck']?.toDate(),
        } as Program;
      }),
    );
  }

  /**
   * This function creates a new program in the database
   * @param program ProgramDto
   */
  async create(program: ProgramDto): Promise<Program> {
    program.createdAt = Timestamp.now();
    program.updatedAt = Timestamp.now();
    const programRef = await addDoc(
      collection(this.firestore, this.collectionStr),
      program,
    );
    const programSnap = await getDoc(programRef);
    return this.convertSnapshotToPrograms(programSnap);
  }

  /**
   * This functions checks if user has an active program
   * @param userId user id
   * @returns
   */
  async hasActiveProgram(userId: string): Promise<boolean> {
    const collectionRef = collection(this.firestore, this.collectionStr);
    const q = query(
      collectionRef,
      where('userId', '==', userId),
      where('status', '==', ProgramStatus.ACTIVE),
      limit(1),
    );
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  }

  /**
   * This function updatates program in the database
   * @param goal Goal
   */
  async update(
    programId: string,
    program: Partial<ProgramDto>,
  ): Promise<Program> {
    program.updatedAt = Timestamp.now();
    const programRef = doc(
      this.firestore,
      `${this.collectionStr}/${programId}`,
    );
    await setDoc(programRef, program, { merge: true });
    const programSnap = await getDoc(programRef);
    return this.convertSnapshotToPrograms(programSnap);
  }

  /**
   * This function deletes program in the database
   * @param programId program id
   */
  async delete(programId: string): Promise<void> {
    const programRef = doc(
      this.firestore,
      `${this.collectionStr}/${programId}`,
    );
    await deleteDoc(programRef);
  }

  private convertSnapshotToPrograms(
    goalSnap:
      | QueryDocumentSnapshot<DocumentData>
      | DocumentSnapshot<DocumentData>,
  ): Program {
    const data = goalSnap.data();
    return {
      ...data,
      id: goalSnap.id,
      createdAt: data?.['createdAt']?.toDate(),
      updatedAt: data?.['updatedAt']?.toDate(),
      nextCheck: data?.['nextCheck']?.toDate(),
    } as Program;
  }
}
