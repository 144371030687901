import { Injectable } from '@angular/core';
import {
  DocumentData,
  Firestore,
  QueryDocumentSnapshot,
  Timestamp,
  addDoc,
  collection,
  collectionData,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  startAfter,
  where,
  limit,
} from '@angular/fire/firestore';
import {
  ContentMessage,
  ContentMessageDto,
  CONTENT_MESSAGES_PER_PAGE,
  GOALMATE_CHAT,
} from '@goalmate/typings';
import { Observable, filter, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContentMessagesRepositoryService {
  protected collectionStr = 'content-messages';

  constructor(protected firestore: Firestore) {}

  /**
   * This function takes userId and lastMessageId if exist and returns a promise that resolves to a ContentMessage array
   * @param {string} userId - string
   * @param {string?} lastMessageId - string
   */
  async getMessages(
    userId: string,
    lastMessageId?: string,
  ): Promise<ContentMessage[]> {
    const collectionRef = collection(this.firestore, this.collectionStr);
    let lastMsgSnap = null;
    if (lastMessageId) {
      lastMsgSnap = await getDoc(
        doc(this.firestore, `${this.collectionStr}/${lastMessageId}`),
      );
    }
    const q = lastMsgSnap
      ? query(
          collectionRef,
          where('userId', '==', userId),
          orderBy('createdAt', 'desc'),
          startAfter(lastMsgSnap),
          limit(CONTENT_MESSAGES_PER_PAGE),
        )
      : query(
          collectionRef,
          where('userId', '==', userId),
          orderBy('createdAt', 'desc'),
          limit(CONTENT_MESSAGES_PER_PAGE),
        );
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(this.convertMsgSnapshotToMessages);
  }

  /**
   * This function takes a program id and programDay and returns an observable that resolves to a Message array
   * @param {string} programId program id
   * @returns {Observable<ContentMessage[]>} observable of Message array
   */
  getNextMessages$(
    userId: string,
    createdAt = new Date(),
  ): Observable<ContentMessage[]> {
    const createdAtTimestamp = Timestamp.fromDate(createdAt);
    const collectionRef = collection(this.firestore, this.collectionStr);
    const q = query(
      collectionRef,
      where('userId', '==', userId),
      where('createdAt', '>', createdAtTimestamp),
      orderBy('createdAt', 'asc'),
    );
    return collectionData(q, { idField: 'id' }).pipe(
      filter((messages) => !!messages.length),
      map((documentsData) => {
        return documentsData.map(
          (data) =>
            ({
              ...data,
              createdAt: data['createdAt']?.toDate(),
            } as ContentMessage),
        );
      }),
    );
  }

  /**
   * This function creates a new content message in the database
   * @param {ContentMessageDto} message ContentMessage
   */
  async create(message: ContentMessageDto): Promise<ContentMessage> {
    message.createdAt = Timestamp.now();
    const msgRef = await addDoc(
      collection(this.firestore, this.collectionStr),
      message,
    );
    const msgSnap = await getDoc(msgRef);
    const data = msgSnap.data();
    if (!data) throw new Error('Message not created. Server error');
    return {
      ...data,
      id: msgSnap.id,
      createdAt: data['createdAt']?.toDate(),
    } as ContentMessage;
  }

  getUnreadCount$(userId: string): Observable<number> {
    const collectionRef = collection(this.firestore, this.collectionStr);
    const q = query(
      collectionRef,
      where('userId', '==', userId),
      where('read', '==', false),
      limit(11),
    );
    return collectionData(q, { idField: 'id' }).pipe(
      map((documentsData) => {
        return documentsData.length;
      }),
    );
  }

  /**
   * This function updatates message in the database
   * @param goal Goal
   */
  async update(
    messageId: string,
    message: Partial<ContentMessageDto>,
  ): Promise<ContentMessage> {
    message.createdAt = Timestamp.now();
    const msgRef = doc(this.firestore, `${this.collectionStr}/${messageId}`);
    await setDoc(msgRef, message, { merge: true });
    const msgSnap = await getDoc(msgRef);
    if (!msgSnap.exists) throw new Error('Message not updated. Server error');
    return this.convertMsgSnapshotToMessages(msgSnap);
  }

  /**
   * This function deletes message in the database
   * @param messageId  message id
   */
  async delete(messageId: string): Promise<void> {
    const msgRef = doc(this.firestore, `${this.collectionStr}/${messageId}`);
    await deleteDoc(msgRef);
  }

  private convertMsgSnapshotToMessages(
    goalSnap: QueryDocumentSnapshot<DocumentData> | DocumentData,
  ): ContentMessage {
    const data = goalSnap.data();
    return {
      ...data,
      id: goalSnap.id,
      goalId: GOALMATE_CHAT,
      createdAt: data['createdAt']?.toDate(),
    } as ContentMessage;
  }
}
