import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { BankGoalActions } from './bank-goal.actions';
import {
  Goal,
  GoalDirection,
  GoalStatus,
  GoalsFilter,
} from '@goalmate/typings';

export const bankGoalsFeatureKey = 'goals';

export interface GoalsBankGoalsState extends EntityState<Goal> {
  loaded: boolean;
  isLoading: boolean;
  isLoadingNextPage: boolean;
  error?: string | null;
  selectedGoalId: string | null;
  hasNextPage: boolean;
  filter: GoalsFilter;
}

export const adapter: EntityAdapter<Goal> = createEntityAdapter<Goal>({
  selectId: (goal: Goal) => goal.id,
  sortComparer: (a, b) => {
    const aDate = new Date(a?.updatedAt as Date).getTime();
    const bDate = new Date(b?.updatedAt as Date).getTime();
    return aDate > bDate ? -1 : 1;
  },
});

export const initialState: GoalsBankGoalsState = adapter.getInitialState({
  loaded: false,
  isLoading: false,
  isLoadingNextPage: false,
  selectedGoalId: null,
  hasNextPage: false,
  filter: {
    direction: [
      GoalDirection.FINANCE,
      GoalDirection.HEALTH,
      GoalDirection.RELATIONSHIP,
      GoalDirection.JOY,
      GoalDirection.OTHER,
    ],
    status: [
      GoalStatus.ACTIVE,
      GoalStatus.COMPLETED,
      GoalStatus.PAUSED,
      GoalStatus.FUTURE,
    ],
  },
});

const reducer = createReducer(
  initialState,
  on(BankGoalActions.loadBankGoals, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(BankGoalActions.addBankGoal, (state, action) =>
    adapter.addOne(action.bankGoal, state),
  ),
  on(BankGoalActions.upsertBankGoal, (state, action) =>
    adapter.upsertOne(action.bankGoal, state),
  ),
  on(BankGoalActions.addBankGoals, (state, action) =>
    adapter.addMany(action.bankGoals, state),
  ),
  on(BankGoalActions.upsertBankGoals, (state, action) =>
    adapter.upsertMany(action.bankGoals, state),
  ),
  on(BankGoalActions.updateBankGoal, (state, action) =>
    adapter.updateOne(action.bankGoal, state),
  ),
  on(BankGoalActions.updateBankGoals, (state, action) =>
    adapter.updateMany(action.bankGoals, state),
  ),
  on(BankGoalActions.deleteBankGoal, (state, action) =>
    adapter.removeOne(action.id, state),
  ),
  on(BankGoalActions.deleteBankGoals, (state, action) =>
    adapter.removeMany(action.ids, state),
  ),
  on(BankGoalActions.setAllBankGoals, (state, action) =>
    adapter.setAll(action.bankGoals, {
      ...state,
      loaded: true,
      isLoading: false,
    }),
  ),
  on(BankGoalActions.clearBankGoals, (state) => adapter.removeAll(state)),
  on(BankGoalActions.setHasNextPage, (state, action) => ({
    ...state,
    hasNextPage: action.hasNextPage,
  })),
  on(BankGoalActions.setBankGoalsFilter, (state, { filter }) => ({
    ...state,
    filter,
  })),
  on(BankGoalActions.loadBankGoalsError, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.error,
  })),
  on(BankGoalActions.loadNextPage, (state) => ({
    ...state,
    isLoadingNextPage: true,
  })),
  on(
    BankGoalActions.updateIsLoadingNextPage,
    (state, { isLoadingNextPage }) => ({
      ...state,
      isLoadingNextPage,
    }),
  ),
);

export function goalBankGoalsReducer(
  state: GoalsBankGoalsState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
