import { createAction, props } from '@ngrx/store';
import { User, UserAuth } from '@goalmate/typings';

export const loginWithEmailAndPassword = createAction(
  '[Auth/Login] Login with email and password',
  props<{ email: string; password: string }>(),
);

export const loginWithGoogle = createAction('[Auth/API] Login with Google');

export const signupWithEmailAndPassword = createAction(
  '[Auth/Signup] Signup with email and password',
  props<{ email: string; password: string }>(),
);

export const setUser = createAction(
  '[Auth] Set User from DB',
  props<{ user: User | null }>(),
);

export const updateUser = createAction(
  '[Auth] Update User',
  props<{ userId: string; user: Partial<User> }>(),
);

export const updateUserSuccess = createAction(
  '[Auth] Update User Success',
  props<{ user: User }>(),
);

export const setAuthUser = createAction(
  '[Auth/Service] Set Auth User',
  props<{ authUser: UserAuth | null }>(),
);

export const loginSuccess = createAction('[Auth/Login] Login Success');

export const loginError = createAction(
  '[Auth/Login] Login Error',
  props<{ error: string }>(),
);

export const updateDailyUsage = createAction(
  '[Auth/User] Update Daily Usages',
  props<{ dailyUsage: number }>(),
);

export const logout = createAction('[Auth/Login] Logout');
