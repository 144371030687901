import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ContentActions } from './content.actions';
import { Content } from '@goalmate/typings';

export const contentsFeatureKey = 'contents';

export interface State extends EntityState<Content> {
  isLoading: boolean;
  loaded: boolean;
  error: string | null;
  selectedContentId: string | null;
}

export const adapter: EntityAdapter<Content> = createEntityAdapter<Content>({
  selectId: (content: Content) => content.id,
  sortComparer: (a, b) => a.day - b.day,
});

export const initialState: State = adapter.getInitialState({
  isLoading: false,
  loaded: false,
  error: null,
  selectedContentId: null,
});

export const reducer = createReducer(
  initialState,
  on(ContentActions.addContent, (state, action) =>
    adapter.addOne(action.content, state),
  ),
  on(ContentActions.upsertContent, (state, action) =>
    adapter.upsertOne(action.content, state),
  ),
  on(ContentActions.addContents, (state, action) =>
    adapter.addMany(action.content, state),
  ),
  on(ContentActions.upsertContents, (state, action) =>
    adapter.upsertMany(action.content, state),
  ),
  on(ContentActions.updateContent, (state, action) =>
    adapter.updateOne(action.content, state),
  ),
  on(ContentActions.updateContents, (state, action) =>
    adapter.updateMany(action.contents, state),
  ),
  on(ContentActions.deleteContent, (state, action) =>
    adapter.removeOne(action.id, state),
  ),
  on(ContentActions.deleteContents, (state, action) =>
    adapter.removeMany(action.ids, state),
  ),
  on(ContentActions.loadContents, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(ContentActions.loadContentsSuccess, (state, action) =>
    adapter.setAll(action.contents, {
      ...state,
      loaded: true,
      isLoading: false,
      error: null,
    }),
  ),
  on(ContentActions.loadContentsError, (state, action) => ({
    ...state,
    loaded: false,
    isLoading: false,
    error: action.error,
  })),
  on(ContentActions.clearContents, (state) => adapter.removeAll(state)),
  on(ContentActions.selectContent, (state, action) => ({
    ...state,
    selectedContentId: action.contentId,
  })),
);

export const contentsFeature = createFeature({
  name: contentsFeatureKey,
  reducer,
  extraSelectors: ({ selectContentsState, selectEntities }) => ({
    ...adapter.getSelectors(selectContentsState),
    selectLoaded: createSelector(selectContentsState, (state) => state.loaded),
    selectIsLoading: createSelector(
      selectContentsState,
      (state) => state.isLoading,
    ),
    selectError: createSelector(selectContentsState, (state) => state.error),
    selectSelectedContent: createSelector(
      selectContentsState,
      selectEntities,
      (state, contents) =>
        state.selectedContentId ? contents[state.selectedContentId] : null,
    ),
  }),
});

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
  selectIsLoading,
  selectLoaded,
  selectError,
  selectContentsState,
} = contentsFeature;
