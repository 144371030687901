export const environment = {
  production: false,
  firebaseWeb: {
    apiKey: 'AIzaSyCVKjFoLYShZl5OQHiQTejTMZNsl5sRGCg',
    authDomain: 'goalmatedev.firebaseapp.com',
    projectId: 'goalmatedev',
    storageBucket: 'goalmatedev.appspot.com',
    messagingSenderId: '554704491636',
    appId: '1:554704491636:web:9c6ebd336f5ae60a736d21',
  },
  firebaseApp: {
    apiKey: 'AIzaSyCVKjFoLYShZl5OQHiQTejTMZNsl5sRGCg',
    authDomain: 'goalmatedev.firebaseapp.com',
    projectId: 'goalmatedev',
    storageBucket: 'goalmatedev.appspot.com',
    messagingSenderId: '554704491636',
    appId: '1:554704491636:web:9c6ebd336f5ae60a736d21',
  },
  firebaseAdmin: {
    apiKey: 'AIzaSyCVKjFoLYShZl5OQHiQTejTMZNsl5sRGCg',
    authDomain: 'goalmatedev.firebaseapp.com',
    projectId: 'goalmatedev',
    storageBucket: 'goalmatedev.appspot.com',
    messagingSenderId: '554704491636',
    appId: '1:554704491636:web:9c6ebd336f5ae60a736d21',
  },
  useEmulators: false,
  host: {
    web: 'https://dev.goalmate.net',
    app: 'https://app-dev.goalmate.net',
    admin: 'https://admin-dev.goalmate.net',
  },
  stripe: {
    stripeKey:
      'pk_test_51NWxSID1LhDXOsE9RLSGkluFxctEg4Agkjc90Xl4mYDAQjfRGxijyxommkSCKP5OUD9PmZgc6xQPceT9kdtby0hP00AGgnUWUX',
    cancelUrl: 'https://app-dev.goalmate.net/dashboard',
    successUrl: 'https://app-dev.goalmate.net/dashboard',
    returnUrl: 'https://app-dev.goalmate.net/dashboard',
  },
  api: {
    emailWaitList: 'https://email-waitlist-zbvqlnt6va-uc.a.run.app',
    validateUniqueEmail:
      'https://users-validateUniqueEmail-zbvqlnt6va-uc.a.run.app',
    mentorWelcomeMsg:
      'https://goalmate-mentoreWelcomeMsg-zbvqlnt6va-uc.a.run.app',
    adminSetCustomClaims: 'https://users-updateclaims-zbvqlnt6va-uc.a.run.app',
    adminSetPublicUserFields:
      'https://users-updatepublicuserfields-zbvqlnt6va-uc.a.run.app',
    usersList: 'https://users-listusers-zbvqlnt6va-uc.a.run.app',
    supportEmail: 'https://email-support-zbvqlnt6va-uc.a.run.app',
    addDayOneMsg: 'https://content-adddayonemsg-zbvqlnt6va-uc.a.run.app',
    textToSpeach: 'https://voice-generate-zbvqlnt6va-uc.a.run.app',
    mentorVoice: 'https://voice-mentor-zbvqlnt6va-uc.a.run.app',
    stripeCheckout: 'https://stripe-checkout-zbvqlnt6va-uc.a.run.app',
    stripePortal: 'https://stripe-portal-zbvqlnt6va-uc.a.run.app',
    deleteAccount: 'https://users-deleteuserrequest-zbvqlnt6va-uc.a.run.app',
    moderateContent: 'https://content-moderate-zbvqlnt6va-uc.a.run.app',
  },
};
