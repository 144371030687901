import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  GoalsBankGoalsState,
  adapter,
  bankGoalsFeatureKey,
} from './bank-goal.reducer';
import {
  GoalBankState,
  goalBankFeatureKey,
} from '../reducers/goal-bank.reducers';

const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

const selectGoalsBankFeatureState =
  createFeatureSelector<GoalBankState>(goalBankFeatureKey);

export const selectGoalBankGoalsState = createSelector(
  selectGoalsBankFeatureState,
  (state) => state[bankGoalsFeatureKey],
);

export const selectGoalIds = createSelector(
  selectGoalBankGoalsState,
  selectIds,
);
export const selectGoalEntities = createSelector(
  selectGoalBankGoalsState,
  selectEntities,
);
export const selectAllGoals = createSelector(
  selectGoalBankGoalsState,
  selectAll,
);

export const selectGoalTotal = createSelector(
  selectGoalBankGoalsState,
  selectTotal,
);

export const selectCurrentGoalId = createSelector(
  selectGoalBankGoalsState,
  (state: GoalsBankGoalsState) => state.selectedGoalId,
);

export const selectCurrentGoal = createSelector(
  selectGoalEntities,
  selectCurrentGoalId,
  (userEntities, goalId) => goalId && userEntities[goalId],
);

export const selectLastGoal = createSelector(
  selectAllGoals,
  selectGoalTotal,
  (goals, totalGoals) => (goals ? goals[totalGoals - 1] : null),
);

export const selectFilter = createSelector(
  selectGoalBankGoalsState,
  (state: GoalsBankGoalsState) => state.filter,
);

export const selectHasNextPage = createSelector(
  selectGoalBankGoalsState,
  (state: GoalsBankGoalsState) => state.hasNextPage,
);

export const selectIsLoading = createSelector(
  selectGoalBankGoalsState,
  (state: GoalsBankGoalsState) => state.isLoading,
);

export const selectIsLoadingNextPage = createSelector(
  selectGoalBankGoalsState,
  (state: GoalsBankGoalsState) => state.isLoadingNextPage,
);

export const selectIsLoaded = createSelector(
  selectGoalBankGoalsState,
  (state: GoalsBankGoalsState) => state.loaded,
);
