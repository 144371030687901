import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { Content } from '@goalmate/typings';

export const ContentActions = createActionGroup({
  source: 'Content/API',
  events: {
    'Load Contents': emptyProps(),
    'Load Contents Success': props<{ contents: Content[] }>(),
    'Load Contents Error': props<{ error: string }>(),
    'Add Content': props<{ content: Content }>(),
    'Upsert Content': props<{ content: Content }>(),
    'Add Contents': props<{ content: Content[] }>(),
    'Upsert Contents': props<{ content: Content[] }>(),
    'Update Content': props<{ content: Update<Content> }>(),
    'Update Contents': props<{ contents: Update<Content>[] }>(),
    'Delete Content': props<{ id: string }>(),
    'Delete Contents': props<{ ids: string[] }>(),
    'Clear Contents': emptyProps(),
    'Select Content': props<{ contentId: string | null }>(),
  },
});
