import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GoalsState, goalsAdapter } from './goals.reducer';
import { Dictionary } from '@ngrx/entity';
import { Goal, GoalStatus, Message, MessageAuthor } from '@goalmate/typings';
import { HomeState, homeFeatureKey } from '../reducers/home.reducers';

const { selectIds, selectTotal, selectAll, selectEntities } =
  goalsAdapter.getSelectors();

const selectHomeFeatureState = createFeatureSelector<HomeState>(homeFeatureKey);

/**
 * Lookup the 'Goals' feature state managed by NgRx
 */
export const selectGoalsState = createSelector(
  selectHomeFeatureState,
  (state) => state.goals,
);

/**
 * select the array of Goal ids
 */
export const selectGoalIds = createSelector(
  selectGoalsState,
  (state: GoalsState) => selectIds(state),
);

/**
 * select the array of Goals
 */
export const selectAllGoals = createSelector(
  selectGoalsState,
  (state: GoalsState) => selectAll(state),
);

/**
 * select the total Goal count
 */
export const selectGoalTotal = createSelector(
  selectGoalsState,
  (state: GoalsState) => selectTotal(state),
);

export const selectGoalsLoaded = createSelector(
  selectGoalsState,
  (state: GoalsState) => state.loaded,
);

export const selectGoalsError = createSelector(
  selectGoalsState,
  (state: GoalsState) => state.error,
);

export const selectGoalsEntities = createSelector(
  selectGoalsState,
  (state: GoalsState) => selectEntities(state),
);

/**
 * select the array of Goals
 */
export const selectSelectedGoalId = createSelector(
  selectGoalsState,
  (state: GoalsState) => state.selectedGoalId,
);

/**
 * get selected Goal
 */
export const selectedGoal = createSelector(
  selectGoalsEntities,
  selectSelectedGoalId,
  (goals: Dictionary<Goal>, goalId: string | null) =>
    goalId ? goals[goalId] : undefined,
);

/**
 * get active Goals
 */
export const selectActiveGoals = createSelector(selectAllGoals, (goals) =>
  goals.filter((g) => g.status === GoalStatus.ACTIVE),
);

/**
 * get archived Goals
 */
export const selectArchivedGoals = createSelector(selectAllGoals, (goals) =>
  goals
    .filter((g) => g.status !== GoalStatus.ACTIVE)
    .sort((a, b) => {
      const aDate = new Date(a?.updatedAt as Date).getTime();
      const bDate = new Date(b?.updatedAt as Date).getTime();
      return aDate > bDate ? -1 : 1;
    }),
);

export const selectHasNextPage = createSelector(
  selectGoalsState,
  (state: GoalsState) => state.hasNextPage,
);

export const selectGoalById = (goalId: string) =>
  createSelector(
    selectGoalsEntities,
    (goals: Dictionary<Goal>) => goals[goalId],
  );

/**
 * get selected Goal messages
 */
export const selectedGoalMessages = createSelector(
  selectedGoal,
  (goal: Goal | undefined) => (goal ? goal.messages || [] : []),
);

/**
 * get Goal messages by goalId
 */
export const selectedGoalMessagesById = (props: { goalId: string }) => {
  return createSelector(selectGoalsState, (state) => {
    const goals = selectEntities(state);
    if (!goals) return [];
    const goal = goals[props.goalId];
    if (!goal) return [];
    return goal ? goal.messages : [];
  });
};

/**
 * get loading flag for messages
 */
export const isLoadingMessages = createSelector(
  selectedGoal,
  (goal: Goal | undefined) => (goal ? goal.isLoadingMessages || false : false),
);

/**
 * get last message of user by goalId
 * @param {string} props goalId
 * @returns {Message} last message
 */
export const selectLastMessage = (props: { goalId: string }) =>
  createSelector(selectGoalsState, (state) => {
    const goals = selectEntities(state);
    const goal = goals[props.goalId];
    if (goal && goal.messages && goal.messages.length > 0) {
      const myMessages = goal.messages.filter(
        (m) => m.author === MessageAuthor.ME,
      );
      return myMessages[myMessages.length - 1];
    }
    return {} as Message;
  });

export const selectReportChecked = createSelector(
  selectGoalsState,
  (state: GoalsState) => state.reportChecked,
);
