import { createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { ContentMessageActions } from './content-message.actions';
import { ContentMessage } from '@goalmate/typings';

export const contentMessagesFeatureKey = 'contentMessages';

export interface ContentMessagesState extends EntityState<ContentMessage> {
  isLoading: boolean;
  isLoaded: boolean;
  hasNextPage: boolean;
  error: string | null;
  unreadCount: number;
}

export const adapter: EntityAdapter<ContentMessage> =
  createEntityAdapter<ContentMessage>({
    selectId: (contentMessage: ContentMessage) => contentMessage.id,
    sortComparer: (a, b) => {
      const aDate = new Date(a?.createdAt as Date).getTime();
      const bDate = new Date(b?.createdAt as Date).getTime();
      return aDate > bDate ? 1 : -1;
    },
  });

export const initialState: ContentMessagesState = adapter.getInitialState({
  isLoading: false,
  isLoaded: false,
  hasNextPage: true,
  error: null,
  unreadCount: 0,
});

export const reducer = createReducer(
  initialState,
  on(ContentMessageActions.addContentMessage, (state, action) =>
    adapter.addOne(action.contentMessage, state),
  ),
  on(ContentMessageActions.upsertContentMessage, (state, action) =>
    adapter.upsertOne(action.contentMessage, state),
  ),
  on(ContentMessageActions.addContentMessages, (state, action) =>
    adapter.addMany(action.contentMessages, {
      ...state,
      isLoading: false,
    }),
  ),
  on(ContentMessageActions.updatePagination, (state, action) => ({
    ...state,
    hasNextPage: action.hasNextPage,
  })),
  on(ContentMessageActions.upsertContentMessages, (state, action) =>
    adapter.upsertMany(action.contentMessages, state),
  ),
  on(ContentMessageActions.updateContentMessage, (state, action) =>
    adapter.updateOne(action.contentMessage, state),
  ),
  on(ContentMessageActions.updateContentMessages, (state, action) =>
    adapter.updateMany(action.contentMessages, state),
  ),
  on(ContentMessageActions.deleteContentMessage, (state, action) =>
    adapter.removeOne(action.id, state),
  ),
  on(ContentMessageActions.deleteContentMessages, (state, action) =>
    adapter.removeMany(action.ids, state),
  ),
  on(ContentMessageActions.loadContentMessages, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(ContentMessageActions.loadContentMessagesNextPage, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(ContentMessageActions.loadContentMessagesSuccess, (state, action) =>
    adapter.setAll(action.contentMessages, {
      ...state,
      isLoaded: true,
      isLoading: false,
      error: null,
    }),
  ),
  on(ContentMessageActions.loadContentMessagesError, (state, { error }) => ({
    ...state,
    isLoading: false,
    error: error,
  })),
  on(ContentMessageActions.clearContentMessages, (state) =>
    adapter.removeAll({ ...state, error: null, isLoaded: false }),
  ),
  on(ContentMessageActions.updateUnreadCount, (state, { count }) => ({
    ...state,
    unreadCount: count,
  })),
);
