import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { PostCommunity } from '@goalmate/typings';

export const PostsActions = createActionGroup({
  source: 'Post/API',
  events: {
    'Load Posts': emptyProps(),
    'Load Posts Error': props<{ error: string }>(),
    'Set Has Next Page': props<{ hasNextPage: boolean }>(),
    'Set Posts': props<{ posts: PostCommunity[] }>(),
    'Add Post': props<{ post: PostCommunity }>(),
    'Upsert Post': props<{ post: PostCommunity }>(),
    'Add Posts': props<{ posts: PostCommunity[] }>(),
    'Upsert Posts': props<{ posts: PostCommunity[] }>(),
    'Update Post': props<{ post: Update<PostCommunity> }>(),
    'Update Posts': props<{ posts: Update<PostCommunity>[] }>(),
    'Delete Post': props<{ id: string }>(),
    'Delete Posts': props<{ ids: string[] }>(),
    'Clear Posts': emptyProps(),
    'Load Next Page': emptyProps(),
    'Update Is Loading Next Page': props<{ isLoadingNextPage: boolean }>(),
    'Select Post': props<{ id: string }>(),
    'Load Comments': props<{ postId: string; lastCommentId?: string }>(),
  },
});
