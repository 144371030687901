import { CompletedGoals, GoalStatus, GoalsStats } from '@goalmate/typings';
import { createAction, props } from '@ngrx/store';

export const initGoalsBank = createAction(
  '[GoalsBank Page] Init',
  props<{ userId: string }>(),
);

export const loadGoalsBankSuccess = createAction(
  '[GoalsBank/API] Load GoalsBank Success',
  props<{ stats: GoalsStats }>(),
);

export const incrementGoalsStats = createAction(
  '[GoalsBank] Increment Goals Stats',
  props<{ status: GoalStatus }>(),
);

export const decrementGoalsStats = createAction(
  '[GoalsBank] Decrement Goals Stats',
  props<{ status: GoalStatus }>(),
);

export const loadGoalsBankFailure = createAction(
  '[GoalsBank/API] Load GoalsBank Failure',
  props<{ error: any }>(),
);

export const updateRouteTracker = createAction(
  '[HomeComponent] Update route urls',
  props<{ routesTracker: { previousUrl: string; currentUrl: string } }>(),
);

export const setAllCompletedGoalsStats = createAction(
  '[HomeComponent / Stats] Load Compledted Goals',
  props<{ stats: CompletedGoals }>(),
);

export const upldateCompletedGoalsStats = createAction(
  '[Goals Bank] Update Compledted Goals',
  props<{ stats: Partial<CompletedGoals> }>(),
);
