import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProgramState } from './program.reducer';
import { Program } from '@goalmate/typings';
import { HomeState, homeFeatureKey } from '../reducers/home.reducers';

const selectHomeFeatureState = createFeatureSelector<HomeState>(homeFeatureKey);

export const selectProgramState = createSelector(
  selectHomeFeatureState,
  (state) => state.program,
);

export const selectProgramLoaded = createSelector(
  selectProgramState,
  (state: ProgramState) => state.loaded,
);

export const selectProgramLoading = createSelector(
  selectProgramState,
  (state: ProgramState) => state.isLoading,
);

export const selectProgramError = createSelector(
  selectProgramState,
  (state: ProgramState) => state.error,
);

export const selectProgram = createSelector(
  selectProgramState,
  (state: ProgramState) => {
    const { loaded, isLoading, error, ...program } = state;
    const isProgram = Object.keys(program).length;
    if (loaded && !isLoading && !error && isProgram) {
      return program as Program;
    } else {
      return null;
    }
  },
);

export const selectProgramId = createSelector(selectProgram, (program) =>
  program ? program.id : null,
);
