import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';

import { GoalsActions } from './goals.actions';
import { Goal } from '@goalmate/typings';

export const goalsFeatureKey = 'goals';

export interface GoalsState extends EntityState<Goal> {
  loaded: boolean;
  error?: string | null;
  selectedGoalId: string | null;
  hasNextPage?: boolean;
  reportChecked: boolean;
}

export interface GoalsPartialState {
  readonly [goalsFeatureKey]: GoalsState;
}

export const goalsAdapter: EntityAdapter<Goal> = createEntityAdapter<Goal>({
  selectId: (goal: Goal) => goal.id,
});

export const initialGoalsState: GoalsState = goalsAdapter.getInitialState({
  loaded: false,
  selectedGoalId: null,
  reportChecked: false,
});

const reducer = createReducer(
  initialGoalsState,
  on(GoalsActions.init, (state) => ({
    ...state,
    loaded: false,
    error: null,
  })),
  on(GoalsActions.error, (state, { error }) => ({
    ...state,
    error,
  })),
  on(GoalsActions.setGoal, (state, { goal }) =>
    goalsAdapter.setOne(goal, { ...state, error: null }),
  ),
  on(GoalsActions.setGoals, (state, { goals }) =>
    goalsAdapter.setAll(goals, { ...state, loaded: true, error: null }),
  ),
  on(GoalsActions.addGoal, (state, { goal }) =>
    goalsAdapter.addOne(goal, { ...state, error: null }),
  ),
  on(GoalsActions.addGoals, (state, { goals }) =>
    goalsAdapter.addMany(goals, { ...state, error: null }),
  ),
  on(GoalsActions.upsertGoal, (state, { goal }) =>
    goalsAdapter.upsertOne(goal, { ...state, error: null }),
  ),
  on(GoalsActions.upsertGoals, (state, { goals }) =>
    goalsAdapter.upsertMany(goals, { ...state, error: null }),
  ),
  on(GoalsActions.updateGoal, (state, { update }) =>
    goalsAdapter.updateOne(update, { ...state, error: null }),
  ),
  on(GoalsActions.updateGoals, (state, { updates }) =>
    goalsAdapter.updateMany(updates, { ...state, error: null }),
  ),
  on(GoalsActions.mapGoal, (state, { entityMap }) =>
    goalsAdapter.mapOne(entityMap, { ...state, error: null }),
  ),
  on(GoalsActions.mapGoals, (state, { entityMap }) =>
    goalsAdapter.map(entityMap, { ...state, error: null }),
  ),
  on(GoalsActions.deleteGoal, (state, { id }) =>
    goalsAdapter.removeOne(id, { ...state, error: null }),
  ),
  on(GoalsActions.deleteGoals, (state, { ids }) =>
    goalsAdapter.removeMany(ids, { ...state, error: null }),
  ),
  on(GoalsActions.deleteGoalsByPredicate, (state, { predicate }) =>
    goalsAdapter.removeMany(predicate, { ...state, error: null }),
  ),
  on(GoalsActions.clearGoals, (state) =>
    goalsAdapter.removeAll({ ...state, loaded: false, error: null }),
  ),
  on(GoalsActions.selectGoal, (state, { id }) => ({
    ...state,
    selectedGoalId: id,
  })),
  on(GoalsActions.loadMessages, (state, { goalId }) =>
    goalsAdapter.updateOne(
      {
        id: goalId,
        changes: {
          isLoadingMessages: true,
        },
      },
      { ...state, error: null },
    ),
  ),
  on(GoalsActions.updateGoalsPagination, (state, { hasNextPage }) => ({
    ...state,
    hasNextPage,
  })),
  on(GoalsActions.updateReportChecked, (state, { reportChecked }) => ({
    ...state,
    reportChecked,
  })),
);

export function goalsReducer(state: GoalsState | undefined, action: Action) {
  return reducer(state, action);
}
