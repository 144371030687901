import {
  DocumentData,
  Firestore,
  QueryDocumentSnapshot,
  Timestamp,
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  startAfter,
  where,
  writeBatch,
  limit,
  DocumentSnapshot,
  getCountFromServer,
  collectionCount,
  Unsubscribe,
} from '@angular/fire/firestore';
import { Injectable, OnDestroy } from '@angular/core';
import {
  ARCHIVED_GOALS_PER_PAGE,
  GOAL_BANK_GOALS_PER_PAGE,
  Goal,
  GoalDirection,
  GoalDto,
  GoalStatus,
  GoalsFilter,
  GoalsStats,
} from '@goalmate/typings';
import { Observable, combineLatest, map, shareReplay } from 'rxjs';
import { Auth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root',
})
export class GoalsRepositoryService implements OnDestroy {
  private unsubscribe!: Unsubscribe;
  private userId!: string;

  protected collectionStr = 'goals';
  protected msgCollectionStr = 'messages';

  constructor(protected firestore: Firestore, private auth: Auth) {
    this.unsubscribe = this.auth.onAuthStateChanged((user) => {
      this.userId = user?.uid || '';
    });
  }

  /**
   * This function returns a promise that resolves to a Goal object or
   * null if the goal does not exist.
   * @param {string} id - goal id
   * @returns {Promise<User | null>} - goal object or null
   */
  async getById(id: string): Promise<Goal | null> {
    const goalRef = doc(this.firestore, `${this.collectionStr}/${id}`);
    const goalSnap = await getDoc(goalRef);
    if (goalSnap.exists()) {
      return { ...goalSnap.data(), id: goalSnap.id } as Goal;
    }
    return null;
  }

  /**
   * This function takes a search query returns a promise that resolves to a Goals array
   * @param {string} userId - string
   * @returns {Promise<Goal[]>} - goal object or null
   */
  async getActiveGoals(userId: string): Promise<Goal[]> {
    const collectionRef = collection(this.firestore, this.collectionStr);
    const q = query(
      collectionRef,
      where('userId', '==', userId),
      where('status', '==', GoalStatus.ACTIVE),
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(this.convertGoalSnapshotToGoal);
  }

  async countActiveGoals(userId: string): Promise<number> {
    const collectionRef = collection(this.firestore, this.collectionStr);
    const q = query(
      collectionRef,
      where('userId', '==', userId),
      where('status', '==', GoalStatus.ACTIVE),
    );
    const snap = await getCountFromServer(q);
    return snap.data().count;
  }

  /**
   * This function takes a search query returns a promise that resolves to Future Goals array
   * @param {string} userId - string
   * @returns {Promise<Goal[]>} - goal object or null
   */
  async getGoalsStats(userId: string): Promise<GoalsStats> {
    const collectionRef = collection(this.firestore, this.collectionStr);
    // Future goals
    const qFuture = query(
      collectionRef,
      where('userId', '==', userId),
      where('status', '==', GoalStatus.FUTURE),
    );
    // Active
    const qActive = query(
      collectionRef,
      where('userId', '==', userId),
      where('status', '==', GoalStatus.ACTIVE),
    );
    // Archive
    const qArchive = query(
      collectionRef,
      where('userId', '==', userId),
      where('status', '==', GoalStatus.PAUSED),
    );
    // Completed
    const qCompleted = query(
      collectionRef,
      where('userId', '==', userId),
      where('status', '==', GoalStatus.COMPLETED),
    );
    const [futureSnap, activeSnap, archiveSnap, completedSnap] =
      await Promise.all([
        getCountFromServer(qFuture),
        getCountFromServer(qActive),
        getCountFromServer(qArchive),
        getCountFromServer(qCompleted),
      ]);
    return {
      [GoalStatus.FUTURE]: futureSnap.data().count,
      [GoalStatus.ACTIVE]: activeSnap.data().count,
      [GoalStatus.PAUSED]: archiveSnap.data().count,
      [GoalStatus.COMPLETED]: completedSnap.data().count,
    };
  }

  getGoalsStats$(): Observable<GoalsStats> {
    const collectionRef = collection(this.firestore, this.collectionStr);
    const userId = this.userId;
    // Future goals 1
    const qFuture = query(
      collectionRef,
      where('userId', '==', userId),
      where('status', '==', GoalStatus.FUTURE),
    );
    // Active
    const qActive = query(
      collectionRef,
      where('userId', '==', userId),
      where('status', '==', GoalStatus.ACTIVE),
    );
    // Archive
    const qArchive = query(
      collectionRef,
      where('userId', '==', userId),
      where('status', '==', GoalStatus.PAUSED),
    );
    // Completed
    const qCompleted = query(
      collectionRef,
      where('userId', '==', userId),
      where('status', '==', GoalStatus.COMPLETED),
    );
    const future$ = collectionCount(qFuture);
    const active$ = collectionCount(qActive);
    const archive$ = collectionCount(qArchive);
    const completed$ = collectionCount(qCompleted);

    return combineLatest([future$, active$, archive$, completed$]).pipe(
      map(([future, active, archive, completed]) => ({
        [GoalStatus.ACTIVE]: active,
        [GoalStatus.PAUSED]: archive,
        [GoalStatus.FUTURE]: future,
        [GoalStatus.COMPLETED]: completed,
      })),
      shareReplay(1),
    );
  }

  async getCompletedGoalsStats(
    userId: string,
    direction: GoalDirection,
  ): Promise<number> {
    const collectionRef = collection(this.firestore, this.collectionStr);
    const q = query(
      collectionRef,
      where('userId', '==', userId),
      where('status', '==', GoalStatus.COMPLETED),
      where('direction', '==', direction),
    );
    const snap = await getCountFromServer(q);
    return snap.data().count;
  }

  /**
   * This function takes a search query returns a promise that resolves to a Goals array by mentor ID
   * @param {string} userId - string
   * @param {string} mentorId - string
   * @returns {Promise<Goal[]>} - goal object or null
   */
  async getActiveGoalsByMentorId(
    userId: string,
    mentorId: string,
  ): Promise<Goal[]> {
    const collectionRef = collection(this.firestore, this.collectionStr);
    const q = query(
      collectionRef,
      where('userId', '==', userId),
      where('status', '==', GoalStatus.ACTIVE),
      where('mentorId', '==', mentorId),
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(this.convertGoalSnapshotToGoal);
  }

  /**
   * This function takes userId, lastGoalId, goalsPerPage and
   * returns a promise that resolves to a Goals array
   * @param userId - string
   * @param lastGoalId - string
   * @param goalsPerPage - number
   * @returns {Promise<Goal[]>}
   */
  async getArchivedGoals(
    userId: string,
    goalsPerPage: number = ARCHIVED_GOALS_PER_PAGE,
    lastGoalId?: string,
  ): Promise<Goal[]> {
    const collectionRef = collection(this.firestore, this.collectionStr);
    let lastGoalSnap = null;
    if (lastGoalId) {
      lastGoalSnap = await getDoc(
        doc(this.firestore, `${this.collectionStr}/${lastGoalId}`),
      );
    }
    const q = lastGoalSnap
      ? query(
          collectionRef,
          where('userId', '==', userId),
          orderBy('updatedAt', 'desc'),
          startAfter(lastGoalSnap),
          limit(goalsPerPage),
        )
      : query(
          collectionRef,
          where('userId', '==', userId),
          orderBy('updatedAt', 'desc'),
          limit(goalsPerPage),
        );
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(this.convertGoalSnapshotToGoal);
  }

  /**
   * Fetches goals based on a provided filter. The filter includes status and direction of the goals.
   * It also supports pagination by providing the last goal id from the previous fetch.
   *
   * @param {string} userId - The ID of the user whose goals are to be fetched.
   * @param {GoalsFilter} filter - The filter to apply when fetching goals. Includes status and direction.
   * @param {string} lastGoalId - The ID of the last goal from the previous fetch. Used for pagination.
   * @returns {Promise<Goal[]>} - A promise that resolves to an array of goals that match the filter.
   */
  async getGoalsByFilter(
    filter: GoalsFilter,
    lastGoalId?: string,
  ): Promise<Goal[]> {
    const userId = this.userId;
    const collectionRef = collection(this.firestore, this.collectionStr);
    let lastGoalSnap = null;
    if (lastGoalId) {
      lastGoalSnap = await getDoc(
        doc(this.firestore, `${this.collectionStr}/${lastGoalId}`),
      );
    }

    const q = lastGoalSnap
      ? query(
          collectionRef,
          where('userId', '==', userId),
          where('status', 'in', filter.status),
          where('direction', 'in', filter.direction),
          orderBy('updatedAt', 'desc'),
          startAfter(lastGoalSnap),
          limit(GOAL_BANK_GOALS_PER_PAGE),
        )
      : query(
          collectionRef,
          where('userId', '==', userId),
          where('status', 'in', filter.status),
          where('direction', 'in', filter.direction),
          orderBy('updatedAt', 'desc'),
          limit(GOAL_BANK_GOALS_PER_PAGE),
        );

    return getDocs(q).then((querySnapshot) =>
      querySnapshot.docs.map(this.convertGoalSnapshotToGoal),
    );
  }

  /**
   * This function returns a promise that resolves to an array of Goal objects
   * @param userId
   * @returns
   */
  async getByUserId(userId: string): Promise<Goal[]> {
    const collectionRef = collection(this.firestore, this.collectionStr);
    const q = query(collectionRef, where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(this.convertGoalSnapshotToGoal);
  }

  /**
   * This function creates a new goal in the database
   * @param goal Goal
   */
  async create(goal: GoalDto): Promise<Goal> {
    goal.createdAt = Timestamp.now();
    goal.updatedAt = Timestamp.now();
    const goalRef = await addDoc(
      collection(this.firestore, this.collectionStr),
      goal,
    );
    const goalSnap = await getDoc(goalRef);
    return this.convertGoalSnapshotToGoal(goalSnap);
  }

  /**
   * This function updatates goal in the database
   * @param goalId Goal ID
   * @param goal Goal
   * @param opt updatedAt boolean
   */
  async update(
    goalId: string,
    goal: Partial<GoalDto>,
    opt: { updatedAt: boolean } = { updatedAt: true },
  ): Promise<Goal> {
    if (opt.updatedAt) goal.updatedAt = Timestamp.now();
    const goalRef = doc(this.firestore, `${this.collectionStr}/${goalId}`);
    await setDoc(goalRef, goal, { merge: true });
    const goalSnap = await getDoc(goalRef);
    return this.convertGoalSnapshotToGoal(goalSnap);
  }

  /**
   * This function updates many goals in the database
   * Make sure to include the id of the goal in the object
   * @param goals
   */
  async updateMany(goals: Array<Partial<Goal>>) {
    const batches = writeBatch(this.firestore);
    goals.forEach((goal) => {
      if (!goal.id) throw new Error('Goal id is required');
      const { id: goalId, ...goalDto } = goal;
      const goalRef = doc(this.firestore, `${this.collectionStr}/${goalId}`);
      batches.update(goalRef, { ...goalDto });
    });
    await batches.commit();
  }

  /**
   * This function deletes goal in the database and all messages associated with it
   * @param goal Goal
   */
  async delete(goalId: string): Promise<void> {
    const batches = writeBatch(this.firestore);
    // Get goal and messages refs
    const goalRef = doc(this.firestore, `${this.collectionStr}/${goalId}`);
    const messagesRef = collection(this.firestore, this.msgCollectionStr);
    // Get all messages for the goa
    const msgQuery = query(messagesRef, where('goalId', '==', goalId));
    const querySnapshot = await getDocs(msgQuery);
    // Add delete operations to batch
    querySnapshot.forEach((doc) => batches.delete(doc.ref));
    batches.delete(goalRef);
    // Execute batch
    await batches.commit();
  }

  private convertGoalSnapshotToGoal(
    goalSnap:
      | QueryDocumentSnapshot<DocumentData>
      | DocumentSnapshot<DocumentData>,
  ): Goal {
    const data = goalSnap.data();
    return {
      ...data,
      id: goalSnap.id,
      createdAt: data?.['createdAt']?.toDate(),
      updatedAt: data?.['updatedAt']?.toDate(),
    } as Goal;
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }
}
