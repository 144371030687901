import { Injectable, inject } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import {
  switchMap,
  catchError,
  of,
  from,
  withLatestFrom,
  map,
  EMPTY,
} from 'rxjs';
import { GoalsActions } from './goals.actions';
import {
  GoalsRepositoryService,
  MessagesRepositoryService,
} from '@goalmate/repository';
import { selectAllGoals, selectedGoalMessagesById } from './goals.selectors';
import { Store } from '@ngrx/store';
import {
  ARCHIVED_GOALS_PER_PAGE,
  GoalStatus,
  MESSAGES_PER_PAGE,
} from '@goalmate/typings';

@Injectable()
export class GoalsEffects {
  private actions$ = inject(Actions);
  private goalsDB = inject(GoalsRepositoryService);
  private messagesDB = inject(MessagesRepositoryService);
  private store = inject(Store);

  getAllByUserId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GoalsActions.getAllByUserId),
      switchMap(({ userId }) => {
        return from(this.goalsDB.getByUserId(userId)).pipe(
          switchMap((goals) => of(GoalsActions.setGoals({ goals }))),
          catchError((error) => {
            console.error('Error', error);
            return of(
              GoalsActions.error({
                error: error.code || 'Server error',
              }),
            );
          }),
        );
      }),
    ),
  );

  getActiveGoals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GoalsActions.getActiveGoals),
      switchMap(({ userId }) => {
        return from(this.goalsDB.getActiveGoals(userId)).pipe(
          map((goals) => GoalsActions.setGoals({ goals })),
          catchError((error) => {
            return of(
              GoalsActions.error({
                error: error.code || 'Server error',
              }),
            );
          }),
        );
      }),
    ),
  );

  selectFirstGoal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GoalsActions.selectFirstGoal),
      withLatestFrom(this.store.select(selectAllGoals)),
      map(([, goals]) => {
        for (let i = 1; i <= 5; i++) {
          const goal = goals
            .filter((g) => g.direction === i && g.status === GoalStatus.ACTIVE)
            .sort((a, b) => {
              const aDate = new Date(a?.createdAt as Date).getTime();
              const bDate = new Date(b?.createdAt as Date).getTime();
              return aDate > bDate ? -1 : 1;
            })[0];
          if (goal) {
            return GoalsActions.selectGoal({ id: goal?.id || null });
          }
        }
        return GoalsActions.selectGoal({ id: null });
      }),
    ),
  );

  loadMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GoalsActions.loadMessages),
      switchMap(({ goalId, lastMessageId }) => {
        return from(this.messagesDB.getMessages(goalId, lastMessageId)).pipe(
          withLatestFrom(
            this.store.select(selectedGoalMessagesById({ goalId })),
          ),
          switchMap(([DBMessages, storeMessages]) => {
            const mergedStoreMessages = lastMessageId
              ? storeMessages || []
              : [];
            const messages = [...DBMessages, ...mergedStoreMessages].sort(
              (a, b) => {
                const aDate = new Date(a?.createdAt as Date).getTime();
                const bDate = new Date(b?.createdAt as Date).getTime();
                return aDate > bDate ? 1 : -1;
              },
            );
            return of(
              GoalsActions.updateGoal({
                update: {
                  id: goalId,
                  changes: {
                    messages,
                    messagesLoaded: true,
                    isLoadingMessages: false,
                    messagesError: undefined,
                    hasNextMessage: DBMessages.length === MESSAGES_PER_PAGE,
                  },
                },
              }),
            );
          }),
          catchError((error) => {
            return of(
              GoalsActions.updateGoal({
                update: {
                  id: goalId,
                  changes: {
                    messages: undefined,
                    messagesLoaded: false,
                    isLoadingMessages: false,
                    messagesError: error.code || 'Server error',
                  },
                },
              }),
            );
          }),
        );
      }),
    ),
  );

  loadNextPage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GoalsActions.loadNextPage),
      switchMap(({ userId, lastGoalId, limit }) => {
        if (limit === 0) return EMPTY;
        return from(
          this.goalsDB.getArchivedGoals(userId, limit, lastGoalId),
        ).pipe(
          switchMap((goals) =>
            of(
              GoalsActions.upsertGoals({
                goals,
              }),
              GoalsActions.updateGoalsPagination({
                hasNextPage: goals.length >= ARCHIVED_GOALS_PER_PAGE,
              }),
            ),
          ),
          catchError((error) => {
            console.error('Error', error);
            return of(
              GoalsActions.error({
                error: error.code || 'Server error',
              }),
            );
          }),
        );
      }),
    );
  });
}
