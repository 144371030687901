import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PostsState, adapter, postsesFeatureKey } from './posts.reducer';

const { selectIds, selectEntities, selectAll, selectTotal } =
  adapter.getSelectors();

const selectPostsFeatureState =
  createFeatureSelector<PostsState>(postsesFeatureKey);

export const selectPostIds = createSelector(selectPostsFeatureState, selectIds);

export const selectPostEntities = createSelector(
  selectPostsFeatureState,
  selectEntities,
);

export const selectAllPosts = createSelector(
  selectPostsFeatureState,
  selectAll,
);

export const selectPostTotal = createSelector(
  selectPostsFeatureState,
  selectTotal,
);

export const selectCurrentPostId = createSelector(
  selectPostsFeatureState,
  (state: PostsState) => state.selectedPostId,
);

export const selectCurrentPost = createSelector(
  selectPostEntities,
  selectCurrentPostId,
  (userEntities, postId) => postId && userEntities[postId],
);

export const selectHasNextPage = createSelector(
  selectPostsFeatureState,
  (state: PostsState) => state.hasNextPage,
);

export const selectIsLoading = createSelector(
  selectPostsFeatureState,
  (state: PostsState) => state.isLoading,
);

export const selectIsLoadingNextPage = createSelector(
  selectPostsFeatureState,
  (state: PostsState) => state.isLoadingNextPage,
);

export const selectIsLoaded = createSelector(
  selectPostsFeatureState,
  (state: PostsState) => state.loaded,
);

export const selectLastPost = createSelector(
  selectAllPosts,
  selectPostTotal,
  (posts, total) => (posts ? posts[total - 1] : null),
);

export const selectPostCommentsById = (postId: string) => {
  return createSelector(selectPostEntities, (entities) => {
    return entities[postId]?.comments || [];
  });
};
