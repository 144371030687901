import { Action, createReducer, on } from '@ngrx/store';
import { ProgramActions } from './program.actions';
import { Program } from '@goalmate/typings';

export const programFeatureKey = 'program';

export interface ProgramState extends Partial<Program> {
  loaded: boolean;
  isLoading: boolean;
  error?: string | null;
}

export const initialState: ProgramState = {
  loaded: false,
  isLoading: false,
};

const reducer = createReducer(
  initialState,
  on(ProgramActions.loadProgramsSuccess, (state, { program }) => ({
    ...state,
    ...program,
    loaded: true,
    isLoading: false,
    error: null,
  })),
  on(ProgramActions.loadProgramsFailure, (state, { error }) => ({
    ...state,
    error,
    loaded: false,
    isLoading: false,
  })),
  on(ProgramActions.addProgram, (state, { program }) => ({
    ...state,
    ...program,
    loaded: true,
    isLoading: false,
    error: null,
  })),
  on(ProgramActions.updateProgram, (state, { program }) => ({
    ...state,
    ...program,
    loaded: true,
  })),
  on(ProgramActions.clearProgram, () => ({
    ...initialState,
    loaded: true,
  })),
  on(ProgramActions.updateProgramIsLoading, (state, { isLoading }) => ({
    ...state,
    isLoading,
  })),
);

export function programReducer(
  state: ProgramState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
