import {
  DocumentData,
  Firestore,
  QueryDocumentSnapshot,
  Timestamp,
  addDoc,
  collection,
  collectionData,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  startAfter,
  where,
  limit,
} from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import {
  MESSAGES_PER_PAGE,
  Message,
  MessageAuthor,
  MessageDto,
} from '@goalmate/typings';
import { Observable, filter, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MessagesRepositoryService {
  protected collectionStr = 'messages';

  constructor(protected firestore: Firestore) {}

  /**
   * This function takes a search query returns a promise that resolves to a Message array
   * @param {string} goalId - string
   * @returns {Promise<Goal[]>} - goal object or null
   */
  async getMessages(
    goalId: string,
    lastMessageId?: string,
  ): Promise<Message[]> {
    const collectionRef = collection(this.firestore, this.collectionStr);
    let lastMsgSnap = null;
    if (lastMessageId) {
      lastMsgSnap = await getDoc(
        doc(this.firestore, `${this.collectionStr}/${lastMessageId}`),
      );
    }
    const q = lastMsgSnap
      ? query(
          collectionRef,
          where('goalId', '==', goalId),
          orderBy('createdAt', 'desc'),
          startAfter(lastMsgSnap),
          limit(MESSAGES_PER_PAGE),
        )
      : query(
          collectionRef,
          where('goalId', '==', goalId),
          orderBy('createdAt', 'desc'),
          limit(MESSAGES_PER_PAGE),
        );
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(this.convertMsgSnapshotToMessages);
  }

  /**
   * This function takes a program id and programDay and returns an observable that resolves to a Message array
   * @param {string} programId program id
   * @returns {Observable<Message[]>} observable of Message array
   */
  getNextMessages$(
    programId: string,
    createdAt = new Date(),
  ): Observable<Message[]> {
    const createdAtTimestamp = Timestamp.fromDate(createdAt);
    const collectionRef = collection(this.firestore, this.collectionStr);
    const q = query(
      collectionRef,
      where('programId', '==', programId),
      where('createdAt', '>', createdAtTimestamp),
      orderBy('createdAt', 'asc'),
    );
    return collectionData(q, { idField: 'id' }).pipe(
      filter((messages) => !!messages.length),
      map((documentsData) => {
        return documentsData.map(
          (data) =>
            ({
              ...data,
              createdAt: data['createdAt']?.toDate(),
            } as Message),
        );
      }),
    );
  }

  async getMessagesByProgramIdAndDay(
    programId: string,
    programDay: number,
  ): Promise<Message[]> {
    const collectionRef = collection(this.firestore, this.collectionStr);
    const q = query(
      collectionRef,
      where('programId', '==', programId),
      where('programDay', '==', programDay),
      where('author', '==', MessageAuthor.ME),
    );
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) return [];
    return querySnapshot.docs.map(this.convertMsgSnapshotToMessages);
  }

  /**
   * This function creates a new goal in the database
   * @param goal Goal
   */
  async create(message: MessageDto): Promise<Message> {
    message.createdAt = Timestamp.now();
    const msgRef = await addDoc(
      collection(this.firestore, this.collectionStr),
      message,
    );
    const msgSnap = await getDoc(msgRef);
    const data = msgSnap.data();
    if (!data) throw new Error('Message not created. Server error');
    return {
      ...data,
      id: msgSnap.id,
      createdAt: data['createdAt']?.toDate(),
    } as Message;
  }

  /**
   * This function updatates message in the database
   * @param goal Goal
   */
  async update(
    messageId: string,
    message: Partial<MessageDto>,
  ): Promise<Message> {
    message.createdAt = Timestamp.now();
    const msgRef = doc(this.firestore, `${this.collectionStr}/${messageId}`);
    await setDoc(msgRef, message, { merge: true });
    const msgSnap = await getDoc(msgRef);
    const data = msgSnap.data();
    if (!data) throw new Error('Message not updated. Server error');
    return {
      ...data,
      id: msgSnap.id,
      createdAt: data['createdAt']?.toDate(),
    } as Message;
  }

  /**
   * This function deletes message in the database
   * @param messageId  message id
   */
  async delete(messageId: string): Promise<void> {
    const msgRef = doc(this.firestore, `${this.collectionStr}/${messageId}`);
    await deleteDoc(msgRef);
  }

  private convertMsgSnapshotToMessages(
    goalSnap: QueryDocumentSnapshot<DocumentData> | DocumentData,
  ): Message {
    const data = goalSnap.data();
    return {
      ...data,
      id: goalSnap.id,
      createdAt: data['createdAt']?.toDate(),
    } as Message;
  }
}
