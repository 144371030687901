import { Program } from '@goalmate/typings';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const ProgramActions = createActionGroup({
  source: 'Program',
  events: {
    'Load Active Program': props<{ userId: string }>(),
    'Load Programs Success': props<{ program: Program | null }>(),
    'Load Programs Failure': props<{ error: string }>(),
    'Add Program': props<{ program: Program }>(),
    'Update Program': props<{ program: Partial<Program> }>(),
    'Clear Program': emptyProps(),
    'Update Program Is Loading': props<{ isLoading: boolean }>(),
  },
});
