import { createReducer, on, Action } from '@ngrx/store';

import * as GoalsBankActions from './goals-bank.actions';
import { CompletedGoals, GoalsStats } from '@goalmate/typings';

export const GOALS_BANK_FEATURE_KEY = 'total';

export interface GoalsBankStatsState {
  loaded: boolean;
  error?: string | null;
  stats?: GoalsStats | undefined;
  completedGoals?: CompletedGoals | undefined;
  routesTracker?: {
    previousUrl: string;
    currentUrl: string;
  };
}

export interface GoalsBankPartialState {
  readonly [GOALS_BANK_FEATURE_KEY]: GoalsBankStatsState;
}

export const initialGoalsBankState: GoalsBankStatsState = {
  loaded: false,
};

const reducer = createReducer(
  initialGoalsBankState,
  on(GoalsBankActions.initGoalsBank, (state) => ({
    ...state,
    loaded: false,
    error: null,
    stats: undefined,
  })),
  on(GoalsBankActions.loadGoalsBankSuccess, (state, { stats }) => ({
    ...state,
    loaded: true,
    stats,
    error: null,
  })),
  on(GoalsBankActions.loadGoalsBankFailure, (state, { error }) => ({
    ...state,
    error,
  })),
  on(GoalsBankActions.updateRouteTracker, (state, { routesTracker }) => ({
    ...state,
    routesTracker,
  })),
  on(GoalsBankActions.setAllCompletedGoalsStats, (state, { stats }) => ({
    ...state,
    completedGoals: stats,
  })),
  on(GoalsBankActions.upldateCompletedGoalsStats, (state, { stats }) => ({
    ...state,
    completedGoals: { ...state.completedGoals, ...stats } as CompletedGoals,
  })),
  on(GoalsBankActions.incrementGoalsStats, (state, { status }) => ({
    ...state,
    stats: {
      ...state.stats,
      [status]: (state.stats as GoalsStats)[status] + 1,
    } as GoalsStats,
  })),
  on(GoalsBankActions.decrementGoalsStats, (state, { status }) => ({
    ...state,
    stats: {
      ...state.stats,
      [status]: (state.stats as GoalsStats)[status] - 1,
    } as GoalsStats,
  })),
);

export function goalsBankStatsReducer(
  state: GoalsBankStatsState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
