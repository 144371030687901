import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, from, of, switchMap } from 'rxjs';
import { ContentMessagesRepositoryService } from '@goalmate/repository';
import { ContentMessageActions } from './content-message.actions';
import { CONTENT_MESSAGES_PER_PAGE } from '@goalmate/typings';

@Injectable()
export class ContentMessagesEffects {
  private actions$ = inject(Actions);
  private contentDB = inject(ContentMessagesRepositoryService);

  loadContent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ContentMessageActions.loadContentMessages),
      switchMap(({ userId }) => {
        return from(this.contentDB.getMessages(userId)).pipe(
          switchMap((contentMessages) =>
            of(
              ContentMessageActions.loadContentMessagesSuccess({
                contentMessages,
              }),
            ),
          ),
          catchError((error) => {
            console.error('Error', error);
            return of(
              ContentMessageActions.loadContentMessagesError({
                error: error.code || 'Server error',
              }),
            );
          }),
        );
      }),
    );
  });

  loadNextPage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ContentMessageActions.loadContentMessagesNextPage),
      switchMap(({ userId, lastMessageId }) => {
        return from(this.contentDB.getMessages(userId, lastMessageId)).pipe(
          switchMap((contentMessages) =>
            of(
              ContentMessageActions.addContentMessages({
                contentMessages,
              }),
              ContentMessageActions.updatePagination({
                hasNextPage:
                  contentMessages.length === CONTENT_MESSAGES_PER_PAGE,
              }),
            ),
          ),
          catchError((error) => {
            console.error('Error', error);
            return of(
              ContentMessageActions.loadContentMessagesError({
                error: error.code || 'Server error',
              }),
            );
          }),
        );
      }),
    );
  });
}
