import { Injectable, OnDestroy, inject } from '@angular/core';
import { Auth, User } from '@angular/fire/auth';
import {
  DocumentData,
  Firestore,
  QueryDocumentSnapshot,
  Timestamp,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  startAfter,
  limit,
  Unsubscribe,
  DocumentReference,
} from '@angular/fire/firestore';
import {
  POSTS_PER_PAGE,
  POST_COMMENTS_PER_PAGE,
  Post,
  PostComment,
  PostCommentDto,
  PostCommunity,
  PostCommunityUser,
  PostDto,
} from '@goalmate/typings';

@Injectable({
  providedIn: 'root',
})
export class PostsRepositoryService implements OnDestroy {
  private firestore = inject(Firestore);
  private auth = inject(Auth);

  private userId!: string;
  private user: User | null = null;
  private unsubscribe!: Unsubscribe;

  /**
   * User cache. It is used to avoid fetching the same user multiple times.
   * The key is the user reference path and the value is the user object.
   * The user object is used in the PostCommunity and PostComment objects.
   */
  private usersMap = new Map<string, PostCommunityUser>();

  protected collectionStr = 'posts';

  constructor() {
    this.unsubscribe = this.auth.onAuthStateChanged((user) => {
      this.user = user;
      this.userId = user?.uid || '';
    });
  }

  generateId(): string {
    const collectionRef = collection(this.firestore, this.collectionStr);
    return doc(collectionRef).id;
  }

  async getCommunityPosts(lastPostId?: string): Promise<PostCommunity[]> {
    const likesSet = new Set<string>();
    const collectionRef = collection(this.firestore, this.collectionStr);
    let lastMsgSnap = null;
    if (lastPostId) {
      lastMsgSnap = await getDoc(
        doc(this.firestore, `${this.collectionStr}/${lastPostId}`),
      );
    }
    const q = lastMsgSnap
      ? query(
          collectionRef,
          orderBy('createdAt', 'desc'),
          startAfter(lastMsgSnap),
          limit(POSTS_PER_PAGE),
        )
      : query(
          collectionRef,
          orderBy('createdAt', 'desc'),
          limit(POSTS_PER_PAGE),
        );
    const querySnapshot = await getDocs(q);

    for (const postSnap of querySnapshot.docs) {
      const data = postSnap.data();
      // Get Likes
      const likeSnap = await getDoc(
        doc(
          this.firestore,
          `${this.collectionStr}/${postSnap.id}/likes/${this.userId}`,
        ),
      );
      if (likeSnap.exists()) likesSet.add(postSnap.id);
      // Get user data
      const userRef = data['userRef'] as DocumentReference;
      await this.getUserByUserRef(userRef);
    }

    return querySnapshot.docs.map((postSnap) => {
      const data = postSnap.data();
      const userRerPath = (data['userRef'] as DocumentReference).path;
      const user = this.usersMap.get(userRerPath);
      delete data['userRef'];
      return {
        ...data,
        user,
        id: postSnap.id,
        liked: likesSet.has(postSnap.id),
        userComment: this.convertPostSnapToPostComment(postSnap),
        createdAt: data['createdAt']?.toDate(),
        updatedAt: data['updatedAt']?.toDate(),
      } as PostCommunity;
    });
  }

  private convertPostSnapToPostComment(
    snap: QueryDocumentSnapshot<DocumentData, DocumentData>,
  ): PostComment {
    const data = snap.data();
    const userRef = data['userRef'] as DocumentReference;
    const userId = userRef.path.split('/')[1];
    const user =
      userId === this.userId
        ? {
            id: this.userId,
            name: this.user?.displayName,
            avatar: this.user?.photoURL,
          }
        : this.usersMap.get(userRef.path);
    return {
      id: snap.id,
      user,
      content: data['content'],
      createdAt: data['createdAt']?.toDate(),
    } as PostComment;
  }

  private async getUserByUserRef(
    userRef: DocumentReference,
  ): Promise<PostCommunityUser> {
    if (this.usersMap.has(userRef.path)) {
      return this.usersMap.get(userRef.path) as PostCommunityUser;
    }
    const userSnap = await getDoc(userRef);
    const user = {
      id: userSnap.id,
      name: userSnap.data()?.['displayName'],
      avatar: userSnap.data()?.['photoURL'],
    };
    this.usersMap.set(userRef.path, user);
    return user;
  }

  async getCommentsByPostId(
    postId: string,
    lastCommentId?: string,
  ): Promise<PostComment[]> {
    const collectionRef = collection(
      this.firestore,
      `${this.collectionStr}/${postId}/comments`,
    );
    let lastCommentSnap = null;
    if (lastCommentId) {
      lastCommentSnap = await getDoc(
        doc(
          this.firestore,
          `${this.collectionStr}/${postId}/comments/${lastCommentId}`,
        ),
      );
    }
    const q = lastCommentSnap
      ? query(
          collectionRef,
          orderBy('createdAt', 'asc'),
          startAfter(lastCommentSnap),
          limit(POST_COMMENTS_PER_PAGE),
        )
      : query(
          collectionRef,
          orderBy('createdAt', 'asc'),
          limit(POST_COMMENTS_PER_PAGE),
        );

    const commentsSnap = await getDocs(q);
    for (const commentSnap of commentsSnap.docs) {
      const userRef = commentSnap.data()['userRef'] as DocumentReference;
      await this.getUserByUserRef(userRef);
    }
    return commentsSnap.docs.map((commentSnap) => {
      const data = commentSnap.data();
      const userRerPath = (data['userRef'] as DocumentReference).path;
      const user = this.usersMap.get(userRerPath);
      delete data['userRef'];
      return {
        ...data,
        user,
        id: commentSnap.id,
        createdAt: data['createdAt']?.toDate(),
      } as PostComment;
    });
  }

  async addComment(postId: string, comment: string): Promise<PostComment> {
    const newComment: PostCommentDto = {
      content: comment,
    };

    newComment.createdAt = Timestamp.now();
    newComment.userRef = doc(this.firestore, 'users-public', this.userId);

    const createCommentPromise = addDoc(
      collection(this.firestore, `${this.collectionStr}/${postId}/comments`),
      newComment,
    ).then((d) => d.id);

    const commentId = await createCommentPromise;

    const me = this.user as User;
    const user = {
      id: me?.uid || '',
      name: me?.displayName || '',
      avatar: me?.photoURL || '',
    };

    return {
      content: comment,
      id: commentId,
      createdAt: newComment.createdAt.toDate(),
      user,
    };
  }

  async getPostById(postId: string): Promise<Post> {
    const postRef = doc(this.firestore, `${this.collectionStr}/${postId}`);
    const postSnap = await getDoc(postRef);
    if (!postSnap.exists()) {
      throw new Error('Post not found');
    }
    return this.convertPostSnapshotToPosts(postSnap);
  }

  /**
   * This function creates a new post in the database
   * @param post Goal
   */
  async create(postDto: PostDto): Promise<Post> {
    postDto.createdAt = Timestamp.now();
    postDto.updatedAt = Timestamp.now();
    postDto.userRef = doc(this.firestore, 'users-public', this.userId);

    const { id, ...post } = postDto;

    const createPostPromise = id
      ? setDoc(doc(this.firestore, `${this.collectionStr}/${id}`), post).then(
          () => id,
        )
      : addDoc(collection(this.firestore, this.collectionStr), post).then(
          (d) => d.id,
        );

    const postId = await createPostPromise;

    return {
      ...postDto,
      id: postId,
      createdAt: postDto.createdAt.toDate(),
      updatedAt: postDto.updatedAt.toDate(),
      userRef: postDto.userRef.path,
    };
  }

  /**
   * This function updatates post in the database
   * @param goal Goal
   */
  async update(postId: string, post: Partial<PostDto>): Promise<Post> {
    post.updatedAt = Timestamp.now();
    const postRef = doc(this.firestore, `${this.collectionStr}/${postId}`);
    await setDoc(postRef, post, { merge: true });
    const postSnap = await getDoc(postRef);
    const data = postSnap.data();
    if (!data) throw new Error('Post not created. Server error');
    return this.convertPostSnapshotToPosts(postSnap);
  }

  /**
   * This function deletes post in the database
   * @param postId - post id
   */
  async delete(postId: string): Promise<void> {
    const msgRef = doc(this.firestore, `${this.collectionStr}/${postId}`);
    await deleteDoc(msgRef);
  }

  async addLike(postId: string): Promise<void> {
    const likeRef = doc(
      this.firestore,
      `${this.collectionStr}/${postId}/likes/${this.userId}`,
    );
    await setDoc(likeRef, {});
  }

  async removeLike(postId: string): Promise<void> {
    const likeRef = doc(
      this.firestore,
      `${this.collectionStr}/${postId}/likes/${this.userId}`,
    );
    await deleteDoc(likeRef);
  }

  /**
   * Converts a Firestore document snapshot into a Post object.
   * The function extracts the data from the snapshot,
   * converts the 'createdAt' and 'updatedAt' fields to Date objects,
   * and returns a Post object.
   *
   * @param {QueryDocumentSnapshot<DocumentData> | DocumentData} postSnap - The Firestore document snapshot to convert.
   * @returns {Post} - The converted Post object.
   */
  private convertPostSnapshotToPosts(
    postSnap: QueryDocumentSnapshot<DocumentData> | DocumentData,
  ): Post {
    const data = postSnap.data();
    const post: Post = {
      ...data,
      id: postSnap.id,
      createdAt: data['createdAt']?.toDate(),
      updatedAt: data['updatedAt']?.toDate(),
    };
    return post;
  }

  ngOnDestroy(): void {
    this.unsubscribe();
    this.usersMap.clear();
  }
}
