import {
  Firestore,
  Timestamp,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
} from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { User } from '@goalmate/typings';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UsersRepositoryService {
  protected collectionStr = 'users';

  private _user$ = new BehaviorSubject<User | null>(null);
  readonly user$ = this._user$.asObservable();
  get user(): User | null {
    return this._user$.getValue();
  }

  constructor(protected firestore: Firestore) {}

  /**
   * This function subscribes to a user document and updates the user$ observable
   * @param userId string
   * @returns void
   */
  watchUser(userId: string): void {
    if (this._user$.getValue()) {
      return;
    }
    onSnapshot(doc(this.firestore, 'users', userId), (doc) => {
      if (!doc.exists()) return;
      const user = {
        ...doc.data(),
        id: doc.id,
        createdAt: doc.data()['createdAt']?.toDate(),
        updatedAt: doc.data()['updatedAt']?.toDate(),
      } as User;
      this._user$.next(user);
    });
  }

  /**
   * This function returns a promise that resolves to a User object or
   * null if the user does not exist.
   * @param {string} id - user id
   * @returns {Promise<User | null>} - user object or null
   */
  async getById(id: string): Promise<User | null> {
    const userRef = doc(this.firestore, `${this.collectionStr}/${id}`);
    const userSnap = await getDoc(userRef);
    if (userSnap.exists()) {
      const userData = userSnap.data();
      return {
        ...userData,
        id: userSnap.id,
        createdAt: userData['createdAt']?.toDate(),
        updatedAt: userData['updatedAt']?.toDate(),
      } as User;
    }
    return null;
  }

  /**
   * This function creates a new user in the database or update an existing one.
   * @param user Partial<User>
   */
  async create(user: Partial<User>): Promise<void> {
    const userRef = doc(this.firestore, `${this.collectionStr}/${user.id}`);
    delete user.id;
    await setDoc(userRef, user, { merge: true });
  }

  /**
   * This function updatates user in the database
   * @param goal Goal
   */
  async update(userId: string, user: Partial<User>): Promise<User> {
    const updatedAt = Timestamp.now();
    const userDto = { ...user, updatedAt };
    const userRef = doc(this.firestore, `${this.collectionStr}/${userId}`);
    await setDoc(userRef, userDto, { merge: true });
    const userSnap = await getDoc(userRef);
    const userData = userSnap.data();
    return {
      ...userData,
      id: userSnap.id,
      createdAt: userData?.['createdAt']?.toDate(),
      updatedAt: userData?.['updatedAt']?.toDate(),
    } as User;
  }

  /**
   * This function checks if a user exists in the database.
   * @param {string} id user id
   * @returns
   */
  async exists(id: string): Promise<boolean> {
    const userRef = doc(this.firestore, `${this.collectionStr}/${id}`);
    const userSnap = await getDoc(userRef);
    return userSnap.exists();
  }
}
