import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import {
  goalsFeatureKey,
  GoalsState,
  goalsReducer,
} from '../goals/goals.reducer';
import {
  programFeatureKey,
  ProgramState,
  programReducer,
} from '../program/program.reducer';
import {
  ContentMessagesState,
  contentMessagesFeatureKey,
  reducer as contentMessagesReducer,
} from '../content-message/content-message.reducer';

export const homeFeatureKey = 'home';

export interface HomeState {
  [goalsFeatureKey]: GoalsState;
  [programFeatureKey]: ProgramState;
  [contentMessagesFeatureKey]: ContentMessagesState;
}

export const homeReducers = new InjectionToken<ActionReducerMap<HomeState>>(
  homeFeatureKey,
  {
    factory: () => ({
      [goalsFeatureKey]: goalsReducer,
      [programFeatureKey]: programReducer,
      [contentMessagesFeatureKey]: contentMessagesReducer,
    }),
  },
);
