import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { ProgramActions } from './program.actions';
import { ProgramRepositoryService } from '@goalmate/repository';

@Injectable()
export class ProgramEffects {
  private programDB = inject(ProgramRepositoryService);

  loadPrograms$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProgramActions.loadActiveProgram),
      switchMap(({ userId }) => {
        return from(this.programDB.getActiveProgram(userId)).pipe(
          switchMap((program) =>
            of(ProgramActions.loadProgramsSuccess({ program })),
          ),
          catchError((error) => {
            console.error('Error', error);
            return of(
              ProgramActions.loadProgramsFailure({
                error: error.code || 'Server error',
              }),
            );
          }),
        );
      }),
    );
  });

  constructor(private actions$: Actions) {}
}
