import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BankGoalActions } from './bank-goal.actions';
import { catchError, from, of, switchMap, withLatestFrom } from 'rxjs';
import { GoalsRepositoryService } from '@goalmate/repository';
import { GOAL_BANK_GOALS_PER_PAGE } from '@goalmate/typings';
import { Store } from '@ngrx/store';
import {
  selectFilter,
  selectHasNextPage,
  selectLastGoal,
} from './bank-goal.selectors';

@Injectable()
export class BankGoalEffects {
  private actions$ = inject(Actions);
  private goalsDB = inject(GoalsRepositoryService);
  private store = inject(Store);

  loadGoals$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BankGoalActions.loadBankGoals),
      switchMap(({ filter }) => {
        return from(this.goalsDB.getGoalsByFilter(filter)).pipe(
          switchMap((goals) => {
            return [
              BankGoalActions.setAllBankGoals({ bankGoals: goals }),
              BankGoalActions.setHasNextPage({
                hasNextPage: goals.length === GOAL_BANK_GOALS_PER_PAGE,
              }),
            ];
          }),
          catchError((error) => {
            console.error(error);
            return of(
              BankGoalActions.loadBankGoalsError({
                error: error.code || 'Error loading goals',
              }),
            );
          }),
        );
      }),
    );
  });

  loadNextPage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(BankGoalActions.loadNextPage),
      withLatestFrom(
        this.store.select(selectFilter),
        this.store.select(selectLastGoal),
        this.store.select(selectHasNextPage),
      ),
      switchMap(([, filter, goal, hasNextPage]) => {
        if (!hasNextPage)
          return of(
            BankGoalActions.updateIsLoadingNextPage({
              isLoadingNextPage: false,
            }),
          );
        const goalId = goal?.id as string;
        return from(this.goalsDB.getGoalsByFilter(filter, goalId)).pipe(
          switchMap((goals) => {
            return [
              BankGoalActions.addBankGoals({ bankGoals: goals }),
              BankGoalActions.setHasNextPage({
                hasNextPage: goals.length === GOAL_BANK_GOALS_PER_PAGE,
              }),
              BankGoalActions.updateIsLoadingNextPage({
                isLoadingNextPage: false,
              }),
            ];
          }),
          catchError((error) => {
            console.error(error);
            return of(
              BankGoalActions.loadBankGoalsError({
                error: error.code || 'Error loading goals',
              }),
            );
          }),
        );
      }),
    );
  });
}
