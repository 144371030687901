import { Injectable, inject } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { switchMap, catchError, of, exhaustMap, from, map } from 'rxjs';
import * as AuthActions from './auth.actions';
import { Router } from '@angular/router';
import {
  Auth,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  updateProfile,
  User as AuthUser,
} from '@angular/fire/auth';
import { UsersRepositoryService } from '@goalmate/repository';
import { AuthUserResponse, TIME_BY_HOURS, User } from '@goalmate/typings';
import { Timestamp } from 'firebase/firestore';

@Injectable()
export class AuthEffects {
  private actions$ = inject(Actions);
  private router = inject(Router);
  private auth = inject(Auth);
  private userDB = inject(UsersRepositoryService);

  loginWithEmailAndPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginWithEmailAndPassword),
      exhaustMap(({ email, password }) => {
        return from(
          signInWithEmailAndPassword(this.auth, email, password),
        ).pipe(
          switchMap(() => {
            this.router.navigate(['/dashboard']);
            return of(AuthActions.loginSuccess());
          }),
          catchError((error) => {
            return of(AuthActions.loginError({ error: error.code }));
          }),
        );
      }),
    ),
  );

  loginWithGoogle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loginWithGoogle),
      exhaustMap(() => {
        return from(signInWithPopup(this.auth, new GoogleAuthProvider())).pipe(
          switchMap(({ user }) => {
            return from(this.userDB.exists(user.uid)).pipe(
              switchMap((exist) => {
                if (!exist) {
                  const newUser = this.convertAuthUserToUser(user as any);
                  return this.userDB.create(newUser);
                }
                return of(null);
              }),
            );
          }),
          switchMap(() => {
            this.router.navigate(['/dashboard']);
            return of(AuthActions.loginSuccess());
          }),
          catchError((error) => {
            return of(AuthActions.loginError({ error: error.code }));
          }),
        );
      }),
    ),
  );

  signupWithEmailAndPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.signupWithEmailAndPassword),
      exhaustMap(({ email, password }) => {
        return from(
          createUserWithEmailAndPassword(this.auth, email, password),
        ).pipe(
          switchMap(({ user }) => {
            this.setAuthUserDisplayName(user);
            const newUser = this.convertAuthUserToUser(user as any);
            return this.userDB.create(newUser);
          }),
          switchMap(() => {
            this.router.navigate(['/dashboard']);
            return of(AuthActions.loginSuccess());
          }),
          catchError((error) => {
            return of(AuthActions.loginError({ error: error.code }));
          }),
        );
      }),
    );
  });

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logout),
        exhaustMap(() => {
          return from(this.auth.signOut()).pipe(
            map(() => this.router.navigate(['/auth/login'])),
            catchError((error) => {
              throw error;
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  updateUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.updateUser),
      exhaustMap(({ userId, user }) => {
        return from(this.userDB.update(userId, user)).pipe(
          map((updatedUser) =>
            AuthActions.updateUserSuccess({ user: updatedUser }),
          ),
          catchError((error) => {
            console.log(error);
            return of(AuthActions.loginError({ error: error.code }));
          }),
        );
      }),
    );
  });

  private convertAuthUserToUser(user: AuthUserResponse) {
    const newUser: Partial<User> = {
      id: user.uid,
      email: user.email,
      displayName: this.getUserDisplayName(user),
      photoURL: user.providerData[0]?.photoURL,
      createdAt: Timestamp.fromDate(
        new Date(user.metadata.creationTime),
      ) as any,
      onboardingCompleted: false,
      dueTime: TIME_BY_HOURS[0],
      timerSeconds: true,
    };
    return newUser;
  }

  private setAuthUserDisplayName(user: AuthUser) {
    updateProfile(user, {
      displayName: this.getUserDisplayName(user),
    }).catch((e) => console.error(e.message));
  }

  private getUserDisplayName(user: any) {
    return user.displayName || user.email?.split('@')[0];
  }
}
