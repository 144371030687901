import { createReducer, on, Action } from '@ngrx/store';
import { UserAuth, User } from '@goalmate/typings';

import * as AuthActions from './auth.actions';

export const AUTH_FEATURE_KEY = 'auth';

export interface AuthState {
  isLoading: boolean;
  authUser?: UserAuth | null;
  user?: User | null;
  error?: string | null;
}

export interface AuthPartialState {
  readonly [AUTH_FEATURE_KEY]: AuthState;
}

export const initialAuthState: AuthState = {
  isLoading: false,
};

const reducer = createReducer(
  initialAuthState,
  on(AuthActions.loginSuccess, (state) => ({
    ...state,
    isLoading: false,
    error: null,
  })),
  on(AuthActions.loginError, (state, { error }) => ({
    ...state,
    error,
    isLoading: false,
  })),
  on(AuthActions.setAuthUser, (state, { authUser }) => ({
    ...state,
    authUser,
  })),
  on(AuthActions.setUser, (state, { user }) => ({
    ...state,
    user,
  })),
  on(AuthActions.updateUserSuccess, (state, { user }) => ({
    ...state,
    user,
    isLoading: false,
    error: null,
  })),
  on(AuthActions.updateDailyUsage, (state, { dailyUsage }) => ({
    ...state,
    user: { ...state.user, dailyUsage } as User,
  })),
);

export function authReducer(state: AuthState | undefined, action: Action) {
  return reducer(state, action);
}
