import { Injectable } from '@angular/core';
import {
  DocumentData,
  DocumentSnapshot,
  Firestore,
  QueryDocumentSnapshot,
  Timestamp,
  addDoc,
  collection,
  collectionData,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from '@angular/fire/firestore';
import { Content, ContentDto, ContentUpdateDto } from '@goalmate/typings';
import { Observable, filter, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContentRepositoryService {
  protected collectionStr = 'contents';

  constructor(protected firestore: Firestore) {}

  /**
   * This function returns a promise that resolves to a Content array
   * @returns {Promise<Content[]>} array of programs
   */
  async getContents(): Promise<Content[]> {
    const collectionRef = collection(this.firestore, this.collectionStr);
    const q = query(collectionRef);
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => this.convertSnapshotToContent(doc));
  }

  async getContentById(contentId: string): Promise<Content | null> {
    const contentRef = doc(
      this.firestore,
      `${this.collectionStr}/${contentId}`,
    );
    const contentSnap = await getDoc(contentRef);
    return contentSnap.exists()
      ? this.convertSnapshotToContent(contentSnap)
      : null;
  }

  async getContentByDay(day: string): Promise<Content[]> {
    const collectionRef = collection(this.firestore, this.collectionStr);
    const q = query(collectionRef, where('day', '==', day));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => this.convertSnapshotToContent(doc));
  }

  /**
   * This function creates a new content in the database
   * @param contentDto ProgramDto
   */
  async create(contentDto: ContentDto): Promise<Content> {
    contentDto.createdAt = Timestamp.now();
    contentDto.updatedAt = Timestamp.now();
    const contentRef = await addDoc(
      collection(this.firestore, this.collectionStr),
      contentDto,
    );
    const contentSnap = await getDoc(contentRef);
    return this.convertSnapshotToContent(contentSnap);
  }

  /**
   * This function updatates content in the database
   * @param contentId content id
   * @param content content
   */
  async update(
    contentId: string,
    content: Partial<ContentUpdateDto>,
  ): Promise<Content> {
    content.updatedAt = Timestamp.now();
    const contentRef = doc(
      this.firestore,
      `${this.collectionStr}/${contentId}`,
    );
    await setDoc(contentRef, content, { merge: true });
    const contentSnap = await getDoc(contentRef);
    return this.convertSnapshotToContent(contentSnap);
  }

  /**
   * This function deletes content in the database
   * @param contentId content id
   */
  async delete(contentId: string): Promise<void> {
    const contentRef = doc(
      this.firestore,
      `${this.collectionStr}/${contentId}`,
    );
    await deleteDoc(contentRef);
  }

  getContentUpdates$(updatedAt: Date): Observable<Content[]> {
    const updatedAtTimestamp = Timestamp.fromDate(updatedAt);
    const collectionRef = collection(this.firestore, this.collectionStr);
    const q = query(collectionRef, where('updatedAt', '>', updatedAtTimestamp));
    return collectionData(q, { idField: 'id' }).pipe(
      filter((contents) => !!contents.length),
      map((documentsData) => {
        return documentsData.map(
          (data) =>
            ({
              ...data,
              createdAt: data['createdAt']?.toDate(),
              updatedAt: data['updatedAt']?.toDate(),
            } as Content),
        );
      }),
    );
  }

  private convertSnapshotToContent(
    goalSnap:
      | QueryDocumentSnapshot<DocumentData>
      | DocumentSnapshot<DocumentData>,
  ): Content {
    const data = goalSnap.data();
    return {
      ...data,
      id: goalSnap.id,
      createdAt: data?.['createdAt']?.toDate(),
      updatedAt: data?.['updatedAt']?.toDate(),
    } as Content;
  }
}
